export const degenesis = {
	"address": "0x2462FA2Bc4B0Cbc501c8546081556fF4F84162De",
	"abi": require("../abis/CandleDegenesisABI.json")
}


export const dialog = {
	"0": {
		"text": [
			"In a world ruled by fear, the meaning of life began to change. King Cold was the first to ascend. He used his will to confront the Devil.",
			"This is the day we were born."],
		"options": ["What happened?", "Who is King Cold?","Haven't we done this before?"],
		"optiontarget": ["1","2","7"],
		"speaker": require("../images/kul.png")
	},
	"1": {
		"text": [
			"People stopped dying. Time stood still.",
			"Eventually it brought us peace."],
		"options": ["What do you mean?", "How long have you been here?"],
		"optiontarget": ["3","3"],
		"speaker": require("../images/kul.png")
	},
	"2": {
		"text": [
			"He is who we have to thank for all of this."],
		"options": ["What do you mean?", "How long have you been here?"],
		"optiontarget": ["3","3"],
		"speaker": require("../images/kul.png")
	},
	"3": {
		"text": [
			"We have all been here for centuries.",
			"Some grew to love it while others grew bored.",
			"Over time we became relatively advanced. In a world without death, we all must coexist. But they kept looking for something else."],
		"options": ["Who are they?", "What did they do?"],
		"optiontarget": ["4","5"],
		"speaker": require("../images/kul.png")
	},
	"4": {
		"text": [
			"The Cult."],
		"options": ["What were they looking for?"],
		"optiontarget": ["5"],
		"speaker": require("../images/kul.png")
	},
	"5": {
		"text": [
			"They started experimenting with older texts and found a way to ascend.",
			"It became the only way to leave."],
		"options": ["Where do they go?"],
		"optiontarget": ["6"],
		"speaker": require("../images/kul.png")
	},
	"6": {
		"text": [
			"No one knows where they go.",
			"They leave everything behind. Their lives, their homes, even their kids.",
			"The Cult lives on through them and the relics."],
		"options": ["What are these relics?","What happens to the kids?","What is this cult?"],
		"optiontarget": ["21","22","23"],
		"speaker": require("../images/kul.png")
	},
	"7": {
		"text": [
			"That's entirely possible, it is hard to be sure which version of yourself you are.",
			"It's also possible this is the first time I am meeting you; and  you recall a meeting which, in my perspective, has yet to occur.",
			"What have you come to do?"],
		"options": ["Explore","Farm"],
		"optiontarget": ["8","9"],
		"speaker": require("../images/kul.png")
	},
	"8": {
		"text": [
			"Ah... an explorer...",
			"But for what purpose do you explore?",
			"Surely you have a purpose."],
		"options": ["...to Earn","...to Learn"],
		"optiontarget": ["10","11"],
		"speaker": require("../images/kul.png")
	},
	"9": {
		"text": [
			"Farm? I have no idea what you mean.",
			"Those consumed by greed must begin again."],
		"options": [],
		"optiontarget": [],
		"speaker": require("../images/kul.png")
	},
	"10": {
		"text": [
			"Earn? An antiquated idea in which I have no interest.",
			"Those consumed by greed must begin again."],
		"options": [],
		"optiontarget": [],
		"speaker": require("../images/kul.png")
	},
	"11": {
		"text": [
			"Then learn you shall.",
			"Ask me a question?"],
		"options": ["What is a Skully?","Who are you?"],
		"optiontarget": ["12","14"],
		"speaker": require("../images/kul.png")
	},
	"12": {
		"text": [
			"The Skully is a magical symbol. Drawn in ritual chalk it can even open portals between worlds. Drawn in regular chalk, it is used to mark Cult friendly businesses and establishments.",
			"What else would you like to know?"],
		"options": ["What is a Ghostly?","Who are you?"],
		"optiontarget": ["13","14"],
		"speaker": require("../images/kul.png")
	},
	"13": {
		"text": [
			"A Ghostly is a mysterious entity that is most commonly found lingering around old ritual grounds.",
			"Can I answer something else for you?"],
		"options": ["Who are you?"],
		"optiontarget": ["14","13"],
		"speaker": require("../images/kul.png")
	},
	"14": {
		"text": [
			"My projection in your universe is called Grim.",
			"But in truth, I am most often called Death.",
			"I precede most of all things in most of all universes in most of all iterations of the Multiverse."],
		"options": ["Do you know where I can find the farms?","What do you mean by projection?"],
		"optiontarget": ["9","15"],
		"speaker": require("../images/kul.png")
	},
	"15": {
		"text": [
			"A projection in this sense, is the four-dimensional cross-section of a higher dimensional entity.",
			"I no longer have time for this. But I'll send you to see my good friend Kali.",
			"She'll ask you for a password. I won't tell you it but here's a hint: the answer is the name of one of my Earthly projections."
			],
		"options": ["Travel to Kali"],
		"optiontarget": ["16"],
		"speaker": require("../images/kul.png")
	},
	"16": {
		"text": [
			"I bring the end of earthly days, a peaceful release in life's maze.",
			"Feared by some, but solace I bring, to those whose journey here is ending.",
			"A shadow that follows the bright sun,",
			"This answer, a timeless one.",
			"Who am I?"],
		"options": ["Aether","Hemera","Thanatos","Hypnos","Eris","Please just let me in..."],
		"optiontarget": ["15","15","17","15","15","15"],
		"speaker": require("../images/kali.png")
	},
	"17": {
		"text": [
			"Nice to meet ya. Name's Kali.",
			"Looks like Grim sent you this way. Probably another one of y'all lookin for the farms.",
			"I won't tell ya where they are, but if you ask me the right questions you might find a few of em."],
		"options": ["Where are the farms?","Do you have any $CULT?","Do you have any $xCULT?","Tell me something I don't know."],
		"optiontarget": ["18","19","20","24"],
		"speaker": require("../images/kali.png")
	},
	"18": {
		"text": [
			"What did I just tell ya?",
			"Those consumed by greed must begin again."
			],
		"options": [],
		"optiontarget": [],
		"speaker": require("../images/kali.png")
	},
	"19": {
		"text": [
			"I have some $CULT laying around here somewhere, look around."],
		"options": ["Check Kali's Dresser","Check Kali's Desk","Check Kali's Nightstand","Check Under Kali's Bed"],
		"optiontarget": ["19","19","pool_cult","19"],
		"speaker": require("../images/kali.png")
	},
	"20": {
		"text": [
			"I have some $xCULT laying around here somewhere, look around."],
		"options": ["Check Kali's Dresser","Check Kali's Desk","Check Kali's Nightstand","Check Under Kali's Bed"],
		"optiontarget": ["20","pool_xcult","20","20"],
		"speaker": require("../images/kali.png")
	},
	"21": {
		"text": [
			"The relics are a part of their rituals.",
			"According to the scrolls, they use candles, chalk, and wands.",
			"There are books with more details but they are difficult to come by."],
		"options": ["What is this cult?","What happens to the kids?"],
		"optiontarget": ["23","22"],
		"speaker": require("../images/kul.png")
	},
	"22": {
		"text": [
			"Most of them study the scrolls in hopes of ascending..",
			"You can typically find them hanging around the auction house where they sell relics."],
		"options": ["What is this cult?"],
		"optiontarget": ["23"],
		"speaker": require("../images/kul.png")
	},
	"23": {
		"text": [
			"That is something you must discover on your own. "],
		"options": [],
		"optiontarget": [],
		"speaker": require("../images/kul.png")
	},
	"24": {
		"text": [
			"Hmmm... something you don't know?",
			"I've a bit of problem controlling my temper.",
			"Maybe that's why in some universes I am called the destroyer."],
		"options": ["What have you destroyed?","What do you mean by other universes?"],
		"optiontarget": ["30","40"],
		"speaker": require("../images/kali.png")
	},
	"30": {
		"text": [
			"Oh pretty much anything you can think of.",
			"Universes, planets, nations, relationships... you name it, I've destroyed it.",
			"Though, I think I've calmed down a bit."
			],
		"options": ["Good for you!","That's terrifying."],
		"optiontarget": ["31","32"],
		"speaker": require("../images/kali.png")
	},
	"31": {
		"text": [
			"Yep, one shouldn't limit themselves to a single talent after all.",
			"This break from destruction has allowed me to hone all sorts of skills.",
			"I've gotten particularly good at creating pairs of my favorite things."
			],
		"options": ["..pairs?","That seems childish."],
		"optiontarget": ["33","34"],
		"speaker": require("../images/kali.png")
	},
	"32": {
		"text": [
			"Maybe...",
			"**Kali turns away and disappears into the darkness."
			],
		"options": [],
		"optiontarget": [],
		"speaker": require("../images/kali.png")
	},

	"33": {
		"text": [
			"Yeah like for example I pair $CULT and $xCULT with all sorts of things"
			],
		"options": ["Do you have any $CULT-$SOUL LP?","Do you have any $xCULT-$SOUL LP?"],
		"optiontarget": ["35","36"],
		"speaker": require("../images/kali.png")
	}, 
	"34": {
		"text": [
			"**Kali grows to a massive size and smashes your body between her index-finger and thumb."
			],
		"options": [],
		"optiontarget": [],
		"speaker": require("../images/kali.png")
	},

	"35": {
		"text": [
			"I have some $CULT-$SOUL LP laying around here somewhere, look around."],
		"options": ["Check Kali's Dresser","Check Kali's Desk","Check Kali's Nightstand","Check Under Kali's Bed"],
		"optiontarget": ["35","35","35","pool_cultlp"],
		"speaker": require("../images/kali.png")
	},
	"36": {
		"text": [
			"I have some $xCULT-$SOUL LP laying around here somewhere, look around."],
		"options": ["Check Kali's Dresser","Check Kali's Desk","Check Kali's Nightstand","Check Under Kali's Bed"],
		"optiontarget": ["pool_xcultlp","36","36","36"],
		"speaker": require("../images/kali.png")
	},
	"40": {
		"text": [
			"Well surely you know this isn't the only one."
			],
		"options": [],
		"optiontarget": [],
		"speaker": require("../images/kali.png")
	},
}

export const story = {
	"start": {
		"text": [
			"Looking back at where you came from, you are left only with the now.",
			"It is dark, but the path you took still illuminates the sky. There is no going back now.",
			"You look around and see a faint light emanating from the dark abyss.",
			"Peering at the light, you just barely discern a neon sign in the distance that reads “The Devil’s Lounge”."],
		"pre-option": "briefcase",
		"options": ["Explore the Dark Abyss", "Walk towards The Devil's Lounge"],
		"optiontarget": ["abyss","DL0"],
		"speaker": require("../images/outside.png"),
		"imgtype": "full"
	},
	"abyss": {
		"text": [
			"A figure approaches, “Do you have the time?”"],
		"options": ["Time is all I have."],
		"optiontarget": ["case"],
		"speaker": require("../images/kul.png"),
		"imgtype": "speaker"
	},
	"case": {
		"text": [
			"That and the briefcase you have in your hand...",
			"Can I tell you a story?"],
		"options": ["Yes","No"],
		"optiontarget": ["x0","start"],
		"speaker": require("../images/kul.png"),
		"imgtype": "speaker"
	},
	"DL0": {
		"text": [
			"You pick up your briefcase and start walking towards the lounge. The buzzing sign towers above. You see the main entrance and a faint light coming from the alleyway."],
		"options": ["Check the Alleyway","Use the Main Entrance"],
		"optiontarget": ["DLalley","DLmain"],
		"speaker": require("../images/lounge.png"),
		"imgtype": "full"
	},
	"DLalley": {
		"text": [
			"You walk past the main entrance and notice your portal still glowing in the sky.",
			"There is a shuffle in the alley. You turn the corner and no one is there.",
			"A light blinks atop a steel door."
			],
		"options": ["Go to the door","Go back to the main entrance"],
		"optiontarget": ["DLalleydoor","DLmain"],
		"speaker": require("../images/alleyway.gif"),
		"imgtype": "full"
	},
	"DLalleydoor": {
		"text": [
			"You see an eye drawn on the door. The door to the peephole slides open against its metal frame.",
			"There is nothing but darkness on the other side. A vacant voice greets you.",
			"“We are closed.”"
			],
		"options": ["Go back to the main entrance"],
		"optiontarget": ["DLmain"],
		"speaker": require("../images/alleyway.gif"),
		"imgtype": "full"
	},

	"DLmain": {
		"text": [
			"You make your way to the main entrance of the lounge. Your footsteps clap against the ground and echo into darkness.",
			"The peephole slides open, grinding against its metal frame.",
			"A piercing stare greets you.",
			"“What’s the password?”"
			],
		"options": [],
		"optiontarget": [],
		"speaker": require("../images/peephole.gif"),
		"imgtype": "full",
		"password": "DLapple"
	},
	"DLapple": {
		"text": [
			"The door to the lounge opens.",
			"The lounge is empty other than a bartender tidying up behind the counter.",
			"The bartender greets you as you take a seat. He puts a napkin down on the bar and places a polished apple in front of you."
			],
		"pre-option":"apple",
		"options": ["Save the Apple for later","Inspect the Apple"],
		"optiontarget": ["DL1","pool_xcult"],
		"speaker": require("../images/bar.gif"),
		"imgtype": "full"
	},
	"DL1": {
		"text": [
			"“Wise choice.” The bartender smiles and picks up another apple.",
			"As he polishes he chuckles, “You know how they love their apples.”",
			"He places the apple on the shelf behind him and then turns back to you and smiles, “What brings you to the lounge?”"
			],
		"options": ["Just selling my soul to the devil","I don't know"],
		"optiontarget": ["pool_candle","DL2"],
		"speaker": require("../images/bar.gif"),
		"imgtype": "full"
	},
	"DL2": {
		"text": [
			"You hear some ruffling behind you so you turn to inspect.",
			"A dark figure reaches out from a small room in the back of the bar and gestures for you to approach.",
			"You turn back to the bartender and try to gauge if he saw the figure. He looks unphased."
			],
		"options": ["Did you see that?","Go to the door."],
		"optiontarget": ["DL3","DLcult"],
		"speaker": require("../images/figure.gif"),
		"imgtype": "full"
	},
	"DLcult": {
		"text": [
			"You cross through the frame and into the darkness."
			],
		"options": ["Explore","Turn Back"],
		"optiontarget": ["pool_cult","DL2"],
		"speaker": require("../images/black.png"),
		"imgtype": "full"
	},
	"DL3": {
		"text": [
			"Probably just another projection..."
			],
		"options": ["Are you a projection?","Are those common here?"],
		"optiontarget": ["DL4","DL5"],
		"speaker": require("../images/bar.gif"),
		"imgtype": "full"
	},
	"DL4": {
		"text": [
			"The bartender laughs. “Something like that.”"
			],
		"options": ["Are they common here?"],
		"optiontarget": ["DL5"],
		"speaker": require("../images/bar.gif"),
		"imgtype": "full"
	},
	"DL5": {
		"text": [
			"“Common? That depends on your perspective. This place has its allure. Here you are after all. We all find our way here eventually.”",
			"He glances at your briefcase. “What's with that briefcase anyway?”"
			],
		"options": ["I don't know","It's something that means a lot to me."],
		"optiontarget": ["stay","DLkey"],
		"speaker": require("../images/bar.gif"),
		"imgtype": "full"
	},
	"DLkey": {
		"text": [
			"“Ah I've heard about items like this before. Certain relics that are bound to a soul. I have one you might like.”"  ,
			"The bartender reaches into their pocket and shows you a key."
			],
		"pre-option": "key",
		"options": ["Check on your $CULT", "Check on your $xCULT", "Check on your $CANDLE", "Harvest Pending $CANDLE"],
		"optiontarget": ["pool_cult","pool_xcult","pool_candle", "harvestMany"],
		"speaker": require("../images/key.gif"),
		"imgtype": "full"
	},

	"x0": {
		"text": [
			"In a world ruled by fear, the meaning of life began to change. King Cold was the first to ascend. He used his will to confront the Devil.",
			"This is the day we were born."],
		"options": ["What happened?", "Who is King Cold?"],
		"optiontarget": ["x1","x2"],
		"speaker": require("../images/kul.png")
	},
	"x1": {
		"text": [
			"People stopped dying. Time stood still.",
			"Eventually it brought us peace."],
		"options": ["What do you mean?", "How long have you been here?"],
		"optiontarget": ["x3","x3"],
		"speaker": require("../images/kul.png")
	},
	"x2": {
		"text": [
			"He is who we have to thank for all of this."],
		"options": ["What do you mean?", "How long have you been here?"],
		"optiontarget": ["x3","x3"],
		"speaker": require("../images/kul.png")
	},
	"x3": {
		"text": [
			"We have all been here for centuries.",
			"Some grew to love it while others grew bored.",
			"Over time we became relatively advanced. In a world without death, we all must coexist. But they kept looking for something else."],
		"options": ["Who are they?", "What did they do?"],
		"optiontarget": ["x4","x5"],
		"speaker": require("../images/kul.png")
	},
	"x4": {
		"text": [
			"The Cult."],
		"options": ["What were they looking for?"],
		"optiontarget": ["x5"],
		"speaker": require("../images/kul.png")
	},
	"x5": {
		"text": [
			"They started experimenting with older texts and found a way to ascend.",
			"It became the only way to leave."],
		"options": ["Where do they go?"],
		"optiontarget": ["x6"],
		"speaker": require("../images/kul.png")
	},
	"x6": {
		"text": [
			"No one knows where they go.",
			"They leave everything behind. Their lives, their homes, even their kids.",
			"The Cult lives on through them and the relics."],
		"options": ["Walk Away"],
		"optiontarget": ["start"],
		"speaker": require("../images/kul.png")
	}

}