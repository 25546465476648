import React from 'react'

import { tokens, lps, farms, routers } from '../configs/CultTokensConfig.js'
import Web3 from 'web3';
var web3 = new Web3(Web3.givenProvider || 'http://localhost:8545');
const fromWei = (wei) => {
  return Number(web3.utils.fromWei(wei.toString(),"ether"))
}
export const BurnPool = (props) => {
	const [isMakingLP, setIsMakingLP] = React.useState(false)
	const [isStakingLP, setIsStakingLP] = React.useState(false)
	const [isUnStakingLP, setIsUnStakingLP] = React.useState(false)
	const [isBreakingLP, setIsBreakingLP] = React.useState(false)
	const [isBurning4Increase, setIsBurning4Increase] = React.useState(false)
	const [isBurning4Decrease, setIsBurning4Decrease] = React.useState(false)

	const turnOffIfOn = (state, setState, cb = () => {}) => {
		if (state) {
			setState(false)
		} else {
			cb()
		}
	}

	const toggleMakingLP = () => {
		turnOffIfOn(
			isMakingLP,
			setIsMakingLP,
			() => {
				turnOffIfOn(isStakingLP, setIsStakingLP)
				turnOffIfOn(isUnStakingLP, setIsUnStakingLP)
				turnOffIfOn(isBreakingLP, setIsBreakingLP)
				turnOffIfOn(isBurning4Increase, setIsBurning4Increase)
				turnOffIfOn(isBurning4Decrease, setIsBurning4Decrease)
				setIsMakingLP(true)
			}
		)
	}

	
	const toggleStakingLP = () => {
		turnOffIfOn(
			isStakingLP,
			setIsStakingLP,
			() => {
				turnOffIfOn(isBreakingLP, setIsBreakingLP)
				turnOffIfOn(isUnStakingLP, setIsUnStakingLP)
				turnOffIfOn(isMakingLP, setIsMakingLP)
				turnOffIfOn(isBurning4Increase, setIsBurning4Increase)
				turnOffIfOn(isBurning4Decrease, setIsBurning4Decrease)
				setIsStakingLP(true)
			}
		)
	}

	
	const toggleUnStakingLP = () => {
		turnOffIfOn(
			isUnStakingLP,
			setIsUnStakingLP,
			() => {
				turnOffIfOn(isStakingLP, setIsStakingLP)
				turnOffIfOn(isBreakingLP, setIsBreakingLP)
				turnOffIfOn(isMakingLP, setIsMakingLP)
				turnOffIfOn(isBurning4Increase, setIsBurning4Increase)
				turnOffIfOn(isBurning4Decrease, setIsBurning4Decrease)
				setIsUnStakingLP(true)
			}
		)
	}

	const toggleBreakingLP = () => {
		turnOffIfOn(
			isBreakingLP,
			setIsBreakingLP,
			() => {
				turnOffIfOn(isStakingLP, setIsStakingLP)
				turnOffIfOn(isUnStakingLP, setIsUnStakingLP)
				turnOffIfOn(isMakingLP, setIsMakingLP)
				turnOffIfOn(isBurning4Increase, setIsBurning4Increase)
				turnOffIfOn(isBurning4Decrease, setIsBurning4Decrease)
				setIsBreakingLP(true)
			}
		)
	}

	const toggleBurning4Increase= () => {
		turnOffIfOn(
			isBurning4Increase,
			setIsBurning4Increase,
			() => {
				turnOffIfOn(isStakingLP, setIsStakingLP)
				turnOffIfOn(isUnStakingLP, setIsUnStakingLP)
				turnOffIfOn(isMakingLP, setIsMakingLP)
				turnOffIfOn(isBreakingLP, setIsBreakingLP)
				turnOffIfOn(isBurning4Decrease, setIsBurning4Decrease)
				setIsBurning4Increase(true)
			}
		)
	}
	
	const toggleBurning4Decrease= () => {
		turnOffIfOn(
			isBurning4Decrease,
			setIsBurning4Decrease,
			() => {
				turnOffIfOn(isStakingLP, setIsStakingLP)
				turnOffIfOn(isUnStakingLP, setIsUnStakingLP)
				turnOffIfOn(isMakingLP, setIsMakingLP)
				turnOffIfOn(isBreakingLP, setIsBreakingLP)
				turnOffIfOn(isBurning4Increase, setIsBurning4Increase)
				setIsBurning4Decrease(true)
			}
		)
	}

	

	var alloc = (props.poolInfo.hasOwnProperty('allocPoint')) ? props.poolInfo.allocPoint : 0
	var depth = (props.poolInfo.hasOwnProperty('totalLp')) ? fromWei(props.poolInfo.totalLp) : 1
	//console.log(props.lpname, props.poolInfo)
	var stake = (props.userInfo.hasOwnProperty('amount')) ? fromWei(props.userInfo.amount) : 0
	//console.log("stake and depth for", props.lpname, stake, depth)

	let smartStake = stake.toString()
	if (smartStake.indexOf(".") == -1) {
		smartStake = Number(smartStake).toLocaleString('en-US')
	} else {
		//console.log("smartStake toFixed",Math.floor(Math.log(Number(stake))/Math.log(10)))
		smartStake = Number(stake).toFixed(2-Math.floor(Math.log(Number(stake))/Math.log(10)))
	}
	var rpb = Number(fromWei(props.ratePerBlock))*Number(alloc)/Number(props.totalAP)
	var rpy = 24*60*30*365*rpb

	var share = Number(stake)/Number(depth)

	const [burn4IncEffect, setBurn4IncEffect] = React.useState("")
	const estimateBurn4IncreaseEffect = () => {
		let amt_candle = props.burn4IncRef.current.value
		let newAlloc = Number(amt_candle) + Number(alloc)
		let newTotal = Number(amt_candle) + Number(props.totalAP)
		let newRpy = 24*60*30*365*Number(fromWei(props.ratePerBlock))*newAlloc/newTotal
		let delta = (newRpy - rpy)*share
		let yearsToRecover = amt_candle/delta
		let newEffect = "Your annual rate increases by " + 
						(delta*share).toFixed(0) + 
						" $CANDLE/year. It will take " +
						yearsToRecover.toFixed(2) +
						" years for you to earn back the candle burned."

		setBurn4IncEffect(newEffect)
	}

	var pend = Number(fromWei(props.pending)).toFixed(2)

	var totalStakeValue = ((Number(depth)/Number(props.totalSupply)) * Number(props.poolValue)).toFixed(2)

	var valuePerYear = (Number(rpy) * Number(props.candlePrice)).toFixed(2)

	var apr = (100*Number(valuePerYear)/Number(totalStakeValue)).toFixed(2)

	return(
	<div className={"burn-pool burn-pool-expanded--" + (isMakingLP || isStakingLP || isBreakingLP || isUnStakingLP || isBurning4Increase || isBurning4Decrease)}>
		<div className="burn-pool__icon col col--0">
			<div className="icon-part">
				<img src={tokens[tokens[props.lpname]["tokens"][0]]["icon"].default} />
			</div>
			<div className="icon-part">
				<img src={tokens[tokens[props.lpname]["tokens"][1]]["icon"].default} />
			</div>
		</div>

		<div className="burn-pool__yourstake col col--1">
			<span className="bf-restrict-deposit">{props.lpBal}</span>
			<span className="bf-restrict-withdraw">{props.lpBal}</span>
			<span className="bf-restrict-burn">{Number(alloc).toLocaleString('en-US')}</span>
			<span className="bf-restrict-info">${totalStakeValue}</span>
		</div>


		<div className="burn-pool__stake col col--2">
			<button 
				className="bf-restrict-deposit" 
				onClick={
					()=>{
						toggleMakingLP()
						
					}
				}>Make LP</button>
			<button 
				className="bf-restrict-withdraw" 
				onClick={()=>{
					toggleBreakingLP()}}>Break LP</button>
			<button 
				className="bf-restrict-burn" 
				onClick={()=>{toggleBurning4Increase()}}>Increase</button>
			
			<span className="bf-restrict-info">{(Number(share)*100).toFixed(2)}%</span>
		</div>
		<div className="burn-pool__yourstake col col--3">
			<span className="bf-restrict-deposit">{smartStake}</span>
			<span className="bf-restrict-withdraw">{smartStake}</span>
			<span className="bf-restrict-burn">{Number((rpy).toFixed(0)).toLocaleString('en-US')}</span>
			<span className="bf-restrict-info">{apr}%</span>
		</div>
		<div className="burn-pool__yourstake col col--3 col--has6">
			<span className="bf-restrict-info">{Number((rpy*share).toFixed(0)).toLocaleString('en-US')}</span>
		</div>
		<div className="burn-pool__stake col col--4">
			<button 
				className="bf-restrict-deposit" 
				onClick={()=>{
					toggleStakingLP()}}>Stake</button>
			<button 
				className="bf-restrict-withdraw" 
				onClick={()=>{
					toggleUnStakingLP()}}>Unstake</button>
			<span className="bf-restrict-burn">{Number((rpy*share).toFixed(0)).toLocaleString('en-US')}</span>
			<button 
				className="bf-restrict-info" 
				onClick={props.harvest}>Claim {pend} CANDLE</button>
			
		</div>
		<div className={"collapsible collapsible--" + isMakingLP + " bf-restrict-deposit"}>
			<div className="collapsible__inside collapsible__inside-quad">
				<div className="icon">
					<img src={tokens[tokens[props.lpname]["tokens"][0]]["icon"].default} />
				</div>
				<input ref={props.makeLPref} defaultValue="0" />
			</div>
			<div className="collapsible__inside collapsible__inside-quad">
				<div className="icon">
					<img src={tokens[tokens[props.lpname]["tokens"][1]]["icon"].default} />
				</div>
				<div className="make-lp-token-b">{Number(props.makeBamt).toFixed(2)}</div>
			</div>
			<div className="collapsible__inside collapsible__inside-quad">
				<button onClick={props.checkMakeLP}>
					Check Output
				</button>
			</div>
			<div className="collapsible__inside collapsible__inside--final collapsible__inside-quad">
				<button onClick={props.makeLP}>
					Build {props.lpname} {tokens[props.lpname]["dex"]} LP
				</button>
			</div>
		</div>

		<div className={"collapsible collapsible--" + isStakingLP + " bf-restrict-deposit"}>
			<div className="collapsible__inside collapsible__inside--half">
				
				<input ref={props.stakeRef} defaultValue="0" />
				<button onClick={props.maxStakeAmount} className="max-input">MAX</button>
			</div>
			
			<div className="collapsible__inside collapsible__inside--half">
				<button onClick={props.stake}>
					Stake {props.lpname} {tokens[props.lpname]["dex"]} LP Tokens
				</button>
			</div>
		</div>

		<div className={"collapsible collapsible--" + isUnStakingLP + " bf-restrict-withdraw"}>
			<div className="collapsible__inside collapsible__inside--half">
				
				<input ref={props.unstakeRef} defaultValue="0" />
				<button onClick={props.maxUnstakeAmount} className="max-input">MAX</button>
			</div>
			
			<div className="collapsible__inside collapsible__inside--half">
				<button onClick={props.unstake}>
					Unstake {props.lpname} {tokens[props.lpname]["dex"]} LP Tokens
				</button>
			</div>
		</div>
		
		<div className={"collapsible collapsible--" + isBreakingLP + " bf-restrict-withdraw"}>
		

			<div className="collapsible__inside collapsible__inside-quad">
				<input className="no-icon" ref={props.breakLPref} defaultValue="0" />
				<button onClick={props.maxBreakAmount} className="max-input">MAX</button>
			</div>
			<div className="collapsible__inside collapsible__inside-quad">
				<button onClick={props.checkBreakLP}>
					Check Output
				</button>
			</div>
			<div className="collapsible__inside collapsible__inside-quad">
				<div className="icon">
					<img src={tokens[tokens[props.lpname]["tokens"][0]]["icon"].default} />
				</div>
				<div className="break-lp-token">{Number(props.breakAamt).toFixed(2)}</div>
				<div className="icon">
					<img src={tokens[tokens[props.lpname]["tokens"][1]]["icon"].default} />
				</div>
				<div className="break-lp-token">{Number(props.breakBamt).toFixed(2)}</div>
			</div>
			
			<div className="collapsible__inside collapsible__inside--final collapsible__inside-quad">
				<button onClick={props.breakLP}>
					Break {props.lpname} {tokens[props.lpname]["dex"]} LP Tokens
				</button>
			</div>
		</div>

		<div className={"collapsible collapsible--" + isBurning4Increase + " bf-restrict-burn"}>
			<div className="collapsible__inside collapsible__inside-quad">
				<input className="no-icon" ref={props.burn4IncRef} defaultValue="0" />
			</div>
			<div className="collapsible__inside collapsible__inside-quad">
				<button onClick={() => {estimateBurn4IncreaseEffect()}}>
					Check Effect
				</button>
			</div>
			<div className="collapsible__inside collapsible__inside-quad">
				<div className="burn-effect">{burn4IncEffect}</div>
			</div>
			<div className="collapsible__inside collapsible__inside-quad">
				<button onClick={props.burn4Inc}>
					Burn $CANDLE
				</button>
			</div>
		</div>

		
	
	</div>)
	}