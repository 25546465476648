import React from 'react'
import { 
  PieChart, 
  Pie, 
  Sector, 
  Cell, 
  ResponsiveContainer, 
  Legend, 
  Label } from 'recharts'

const COLORS = ['#FF4A4A', '#FF6A4D', '#FF9052', '#FFB655', '#FFD757', '#FFFFFF']
//const COLORS = ['#38f6ff', '#71ffbb', '#00cc69', '#00c2cc', '#adfbff', '#c2ffe1']

const renderLabel = (entry) => {
  if (entry.hasOwnProperty('labeltype') && entry.hasOwnProperty('labelafter')) {
    return Number(entry.value.toFixed(0)).toLocaleString('en-US') + ' ' + entry.labelafter
  } else {
    return "$" + entry.value.toFixed(2)
  }
  
}

export const PiCh = (props) => {
    if (props.data.length > 0) {
      //console.log("pie chart data", props.data)
    }
    
    return (
      <ResponsiveContainer width="100%" height="100%" minWidth={100} minHeight={100}>
        <PieChart width={400} height={400}>
          <Legend verticalAlign="top" height={36} />
          <Pie
            data={props.data}
            cx="50%"
            cy="50%"
            labelLine 
            label={renderLabel}
            legend
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
          >
            {props.data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} stroke="#000" strokeWidth="1" />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    );
  
}
