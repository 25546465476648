import React from 'react';
import Web3 from 'web3';
import { chainMap, enforceChain } from './tools/ChainTools.js'
import { degenesis, dialog, story } from './configs/CandleDegenesisConfig.js'
import { tokens, lps, farms, routers } from './configs/CultTokensConfig.js'
import { nfts } from './configs/CultNFTConfig.js' 
import { CF } from './tools/CF.js'
import { BF } from './tools/BF.js'
import { fromAddressTo, formatTokenBalance, fromTokenNameToAddress, tokenNameAddyMap, fromTokenNameToDecimals } from './tools/tokenUtilities.js'
import { PiCh } from './piechart.js'
import { ValEl, InfoRow, InfoRowP, InfoRow_1155, HeaderRowP, ClaimRow, BoxRow, BoxPart } from './tools/InfoRow.js'
import { BurnPool } from './tools/BurnPool.js'
import { addLiquidity } from './tools/addLiquidity.js'
import { breakLiquidity } from './tools/breakLiquidity.js'

import { skullys, popskullys, ghostlys, cult, xcult, candle, soul, farm, bf, cultItems, lp_cult, lp_xcult, lp_candle, lp_candleusdc, slp_cult, slp_xcult, lp_cultxcult } from './tools/Tract.js'
const bg = require("./images/store-front.gif")
const idep = require("./images/deposit.png") 
const iw = require("./images/withdraw.png")
const ic = require("./images/candle.png")
const pools = ["cult","xcult","candle"]
const bf_pools = ["cult-ftm", "xcult-ftm", "candle-usdc", "cult-xcult"]
const pnt = "https://paintswap.finance/marketplace/fantom/assets/"
const itemImgs = {
  "1": "https://candle.farmgod.finance/static/media/apple.png",
  "11": "https://candle.farmgod.finance/static/media/briefcase_plain.png",
  "21": "https://candle.farmgod.finance/static/media/key.png"
}

const mainpath = [
  "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83",
  "0xe2fb177009FF39F52C0134E8007FA0e4BaAcBd07",
  "0x208234F4f8B1bBEA59cfDc38666141654e851DCe"
]

const fs = (bal, dec = 18) => {
  let x = bal.toString()
  let digits, decimals
  let L = "0", R = "0"
  let output
  //console.log(x.length, dec)
  if (x.length > dec) {
    digits = x.length - dec
    L = x.slice(0,digits)
    R = x.slice(digits)
  } else {
    decimals = dec - x.length
    R = "0".repeat(decimals).concat(x)
  }
  output = L.concat(".").concat(R)
  output = new Intl.NumberFormat().format(output)
  return output
}

const InputView = (props) => (

  <div className={"theInput TOGGLE__theInput--" + props.toggle + " INTENT__theInput--" + props.intent}>
        <input type="text" id="theInput" defaultValue="0" ref={props.theInputRef} />
        <button className="btn btn--max" onClick={props.setInputRefToMAX}>MAX</button>
        <div className="theInput__poolbalance">Pool Balance: <span>{props.poolBalance}</span></div>

        <div className="theInput__balance">Balance: <span>{props.balance}</span></div>
        <div className="theInput__allowance">Allowance: <span>{props.allowance}</span></div>
        <button className="btn btn--back" onClick={props.toggleInput}>back</button>
        <button className="btn btn--approve" onClick={props.onClick_Approve}>Approve This Amount</button>
        <button className="btn btn--deposit" onClick={props.triggerDeposit}>Deposit This Amount</button>
        <button className="btn btn--withdraw" onClick={props.triggerWithdraw}>Withdraw This Amount</button>
      </div>
      )

export function Dapp() {
  // this is a reference to the input field
  const theInputRef = React.createRef();
  const skullyIdRef = React.createRef();
  
  const [theTargetPool, setTheTargetPool] = React.useState(0)

  // state for managing whether a transaction is pending
  const [isPending, setIsPending] = React.useState(false);
  const PENDING_MESSAGE = "Pending Transaction"
  const [pendingMessage, setPendingMessage] = React.useState(PENDING_MESSAGE)

  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // Connecting to Metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  const [connected, setConnected] = React.useState(false)
  const [accounts, setAccounts] = React.useState([]);
  const [mmBtnText, setMMBtnText] = React.useState("Connect");


  // attached to the accountsChanged event listener
  // triggered once manually via connectMM
  function handleNewAccounts(newAccounts) {
    setAccounts(newAccounts);
  }

  // attached to the chainChanged event listener
  // triggered once manually via main hook
  // calls letItRip if the proper chain is selected
  function handleChainChange(chainId) {
    setMMBtnText("Connected to " + chainMap(window.ethereum.chainId));
     enforceChain("Fantom", letItRip)
  }

  // when triggered, connectMM requests the user connects to the dApp
  // if the user is already connected, or after the user connects,
  // connectMM sets the accounts state to the user's connected accounts,
  // and sets the connected state to true
  const connectMM = () => {
      if (!connected) {
        window.ethereum
          .request({ method: 'eth_requestAccounts' })
          .then((newAccounts) => {
            handleNewAccounts(newAccounts)
            setConnected(true)})
      }
  }

  
  React.useEffect(() => {
    if (connected) {
      window.ethereum.on('accountsChanged', handleNewAccounts);
      window.ethereum.on('chainChanged', handleChainChange);
      setChartData([])
      return () => {
        window.ethereum.on('accountsChanged', handleNewAccounts);
        window.ethereum.on('chainChanged', handleChainChange);
      };
    }
  }, [connected]);



  
  // --------- -------------------------------------------------------------------------------
  // MAIN HOOK -------------------------------------------------------------------------------
  // --------- -------------------------------------------------------------------------------

  // if a user is connected with at least one account,
  // trigger the handleChainChange function
  React.useEffect( () => {
    if (connected) {
        if (accounts.length > 0) {
          handleChainChange(window.ethereum.chainId)  
        }
      }
  }, [connected])


  // --------- -------------------------------------------------------------------------------

  // -- end of connecting to metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----

  const [isDisabled, setDisabled] = React.useState(false);

var web3 = new Web3(Web3.givenProvider || 'http://localhost:8545');

var itemIds = [1, 2, 11, 12, 13, 21, 22]

var dgn = new CF(web3,"degenesis",theInputRef)
var farm_burn = new BF(web3,"burn")
var farm_gate = new CF(web3,"gate",theInputRef)

const [poolLength, setPoolLength] = React.useState(0)
const getPoolLength = () => {
  farm.methods.poolLength()
    .call({from: window.ethereum.selectedAddress})
    .then((res) => {
      setPoolLength(Number(res))
    })
}

// get the poolInfo for each pool
const [poolInfo0,setPoolInfo0] = React.useState({})
const [poolInfo1,setPoolInfo1] = React.useState({})
const [poolInfo2,setPoolInfo2] = React.useState({})

const getPoolInfo = (pid) => {
  return () => {
    farm.methods.poolInfo(pid)
      .call({from: window.ethereum.selectedAddress})
      .then((res) => {
        eval("setPoolInfo" + pid.toString() + "(res)")
      })
  }
}

// get the userInfo for each pool
const [userInfo0,setUserInfo0] = React.useState({})
const [userInfo1,setUserInfo1] = React.useState({})
const [userInfo2,setUserInfo2] = React.useState({})

const getUserInfo = (pid) => {
  return () => {
    farm.methods.userInfo(pid,window.ethereum.selectedAddress).call({from: window.ethereum.selectedAddress})
      .then((res) => {
        eval("setUserInfo" + pid.toString() + "(res)")
      })
  }
}
// get the userInfo for each pool
const [pending0,setPending0] = React.useState({})
const [pending1,setPending1] = React.useState({})
const [pending2,setPending2] = React.useState({})


const getPending = (pid) => {
  return () => {
    farm.methods.pendingCandle(pid,window.ethereum.selectedAddress)
      .call({from: window.ethereum.selectedAddress})
      .then((res) => {
        eval("setPending" + pid.toString() + "(res)")
      })
  }
}

const getAllPoolData = () => {
  let f, g, h
  for (var i = 0; i < poolLength; i++) {
    f = getPoolInfo(i)
    g = getUserInfo(i)
    h = getPending(i)
    f()
    g()
    h()
  }
}

// when the poolLength changes from 0
  // get the poolInfo for each pool
  React.useEffect(() => {
    if (poolLength > 0) {
      getAllPoolData()
    }
  }, [poolLength])

// ------------------------------------------------------------------------------------------
const [bf_emissionRate, set_bf_emissionRate] = React.useState("")
const [bf_totalAP, set_bf_totalAP] = React.useState("")
const [bf_poolLength, set_bfPoolLength] = React.useState(0)
const [bf_perBlockChangeUnit, set_bfPerBlockChangeUnit] = React.useState(0)
const get_bfPoolLength = () => {
  bf.methods.poolLength()
    .call({from: window.ethereum.selectedAddress})
    .then((res) => {
      set_bfPoolLength(Number(res))
    })
}

const get_bfRate = () => {
  bf.methods.candlePerBlock()
    .call({from: window.ethereum.selectedAddress})
    .then((res) => {
      set_bf_emissionRate(res)
    })
}

const get_bfAP = () => {
  bf.methods.totalAllocPoint()
    .call({from: window.ethereum.selectedAddress})
    .then((res) => {
      set_bf_totalAP(res)
    })
}

const get_bfPerBlockChangeUnit = () => {
  bf.methods.perBlockChangeUnit()
    .call({from: window.ethereum.selectedAddress})
    .then((res) => {
      set_bfPerBlockChangeUnit(res)
    })
}

// get the poolInfo for each pool
const [bf_poolInfo0,set_bfPoolInfo0] = React.useState({})
const [bf_poolInfo1,set_bfPoolInfo1] = React.useState({})
const [bf_poolInfo2,set_bfPoolInfo2] = React.useState({})
const [bf_poolInfo3,set_bfPoolInfo3] = React.useState({})
const get_bfPoolInfo = (pid) => {
  return () => {
    bf.methods.poolInfo(pid)
      .call({from: window.ethereum.selectedAddress})
      .then((res) => {
        //console.log("get_bfPoolInfo ", res)
        eval("set_bfPoolInfo" + pid.toString() + "(res)")
      })
  }
}

// get the userInfo for each pool
const [bf_userInfo0,set_bfUserInfo0] = React.useState({})
const [bf_userInfo1,set_bfUserInfo1] = React.useState({})
const [bf_userInfo2,set_bfUserInfo2] = React.useState({})
const [bf_userInfo3,set_bfUserInfo3] = React.useState({})
const get_bfUserInfo = (pid) => {
  return () => {
    bf.methods.userInfo(pid,window.ethereum.selectedAddress).call({from: window.ethereum.selectedAddress})
      .then((res) => {
        console.log("get_bfUserInfo ", res)
        eval("set_bfUserInfo" + pid.toString() + "(res)")
      })
  }
}
// get the userInfo for each pool
const [bf_pending0,set_bfPending0] = React.useState("")
const [bf_pending1,set_bfPending1] = React.useState("")
const [bf_pending2,set_bfPending2] = React.useState("")
const [bf_pending3,set_bfPending3] = React.useState("")

const get_bfPending = (pid) => {
  return () => {
    bf.methods.pendingCandle(pid,window.ethereum.selectedAddress)
      .call({from: window.ethereum.selectedAddress})
      .then((res) => {
        eval("set_bfPending" + pid.toString() + "(res)")
        console.log(res)
      })
  }
}

const get_bfAllPoolData = () => {
  let f, g, h, r
  for (var i = 0; i < bf_poolLength; i++) {
    f = get_bfPoolInfo(i)
    g = get_bfUserInfo(i)
    h = get_bfPending(i)
    r = get_bfPending(i)
    f()
    g()
    h()
    r()
  }
}

// when the poolLength changes from 0
  // get the poolInfo for each pool
  React.useEffect(() => {
    if (bf_poolLength > 0) {
      get_bfAllPoolData()
    }
  }, [bf_poolLength])

// ------------------------------------------------------------------------------------------

// this function checks if all the info is fetched from the chain
  const isInfoReady = () => {
    let x = true
    var poolCheck = "'0' in poolInfo"
    var userCheck = "'0' in userInfo"
    for (var i = 0; i < poolLength; i++) {
      console.log(poolCheck + i.toString())
      console.log(eval(poolCheck + i.toString()))
      console.log(poolInfo0)
      if (!eval(poolCheck + i.toString()) || !eval(userCheck + i.toString())) {
        x = false
        break
      }
    }
    console.log(x, "isInfoReady")
    return x;
  }

// this state manages the intent of the input overlay
  // it should be set to false initially,
  // and then set to either "add" or "remove"
  const [theInputINTENT, setTheInputINTENT] = React.useState(false);

  // when a user opens the input overlay,
  // this state gets set to the address of the staking token for the target pool
  const [theTargetADDY, setTheTargetADDY] = React.useState("");

  // when a user opens the input overlay,
  // this state gets set to the address of the staking token for the target pool
  const [theTargetNAME, setTheTargetNAME] = React.useState("");

  // when a user opens the input overlay,
  // this state gets set to the pool ID of the target pool
  const [theTargetPOOL, setTheTargetPOOL] = React.useState(0);

  // this state manages the display of the input overlay
  const [theInputTOGGLE, setTheInputTOGGLE] = React.useState(false);

  // this function manages the toggling of theInputTOGGLE state
  const toggleInput = () => {
    console.log(theInputTOGGLE, "toggling")
    if (theInputTOGGLE) {
      setTheInputTOGGLE(false)
    } else {
      setTheInputTOGGLE(true)
    }
  }

 const setInputRefToMAX = () => {
    if (theInputINTENT == "remove") {
      theInputRef.current.value = formatTokenBalance(
        fromTokenNameToDecimals(theTargetNAME), 
        eval("userInfo" + theTargetPOOL +"['0']"), 
        false)
    } else {
      
      theInputRef.current.value = formatTokenBalance(
        fromTokenNameToDecimals(theTargetNAME), 
        balanceOfTarget, 
        false)
    }
    
  }
 // state for storing,
  // and function for setting,
  // the input overlay's display of the user's wallet balance of the staking token
  const [balanceOfTarget, setBalanceOfTarget] = React.useState(0);
  const smartSetBalanceOfTarget = (balance) => {
    console.log(balance, "balance of target")
    setBalanceOfTarget(balance)
  }

  // state for storing,
  // and function for setting,
  // the input overlay's display of the user's allowance (to the CHEF) of the staking token
  const [allowanceOfTarget, setAllowanceOfTarget] = React.useState(0);
  const smartSetAllowanceOfTarget = (allowance) => {
    console.log(allowance, "allowance of target")
    setAllowanceOfTarget(allowance)
  }
// onClick function for approving a given amount of the staking token
  const onClick_Approve = () => {
    setIsPending(true)
    farm_gate.triggerApproval(
      theTargetADDY, 
      (allowance) => {
        smartSetAllowanceOfTarget(allowance)
        setIsPending(false)
      } )
  }
  

  // onClick function for depositing to theTargetPOOL
  const triggerDeposit = () => {
    setIsPending(true)
    console.log("target pool: " + theTargetPOOL)
    farm_gate.depositAmount(
      theTargetPOOL, pools[theTargetPOOL],
      () => {
        setIsPending(false)
        toggleInput();
        let f = getUserInfo(theTargetPOOL)
        f()
      }
    )
  }

  // onClick function for withdrawing from theTargetPOOL
  const triggerWithdraw = () => {
    setIsPending(true)
    farm_gate.withdrawAmount(
      theTargetPOOL, pools[theTargetPOOL],
      () => {
        setIsPending(false)
        toggleInput();
        let f = getUserInfo(theTargetPOOL)
        f()
        let g = getPending(theTargetPOOL)
        g()
      }
    )
  }
const letItRip = () => {
  getUserBalances()
}

const [balSkully, setBalSkully] = React.useState(0)
const [balPopSkully, setBalPopSkully] = React.useState(0)
const [balGhostly, setBalGhostly] = React.useState(0)

const [bal_item1, set_bal_item1] = React.useState(0)
const [bal_item2, set_bal_item2] = React.useState(0)
const [bal_item11, set_bal_item11] = React.useState(0)
const [bal_item12, set_bal_item12] = React.useState(0)
const [bal_item13, set_bal_item13] = React.useState(0)
const [bal_item21, set_bal_item21] = React.useState(0)
const [bal_item22, set_bal_item22] = React.useState(0)
const [bal_item31, set_bal_item31] = React.useState(0)
const [questReady, setQuestReady] = React.useState(false)
const get1155Balance = (nft, id, setState) => {
  nft.methods.balanceOf(window.ethereum.selectedAddress, id)
    .call({from: window.ethereum.selectedAddress})
    .then((res)=> {
      setState(Number(res))
      if (id == 11) {
        setQuestReady(true)
      }
    })
}

const getNFTBalance = (nft, setState) => {
  nft.methods.balanceOf(window.ethereum.selectedAddress)
    .call({from: window.ethereum.selectedAddress})
    .then((res)=> {
      setState(res)
    })
}

const [balCult, setBalCult] = React.useState(0)
const [balXCult, setBalXCult] = React.useState(0)
const [balCandle, setBalCandle] = React.useState(0)
const [bal_lp_cult, setBal_lp_cult] = React.useState(0)
const [bal_lp_xcult, setBal_lp_xcult] = React.useState(0)
const [bal_lp_candle, setBal_lp_candle] = React.useState(0)
const [bal_lp_candleusdc, setBal_lp_candleusdc] = React.useState(0)
const [bal_lp_cultxcult, setBal_lp_cultxcult] = React.useState(0)
const [bal_slp_cult, setBal_slp_cult] = React.useState(0)
const [bal_slp_xcult, setBal_slp_xcult] = React.useState(0)
const getTokenBalance = (token, setState) => {
  token.methods.balanceOf(window.ethereum.selectedAddress)
    .call({from: window.ethereum.selectedAddress})
    .then((res)=> {
      setState(res)
    })
}

const getUserBalances = () => {
  getNFTBalance(skullys, setBalSkully)
  getNFTBalance(popskullys, setBalPopSkully)
  getNFTBalance(ghostlys, setBalGhostly)
  getTokenBalance(cult, setBalCult)
  getTokenBalance(xcult, setBalXCult)
  getTokenBalance(candle, setBalCandle)
  getTokenBalance(lp_cult, setBal_lp_cult)
  getTokenBalance(lp_xcult, setBal_lp_xcult)
  getTokenBalance(lp_candle, setBal_lp_candle)
  getTokenBalance(slp_cult, setBal_slp_cult)
  getTokenBalance(slp_xcult, setBal_slp_xcult)
  getTokenBalance(lp_candleusdc, setBal_lp_candleusdc)
  getTokenBalance(lp_cultxcult, setBal_lp_cultxcult)
  get1155Balance(cultItems, 1, set_bal_item1)
  get1155Balance(cultItems, 2, set_bal_item2)
  get1155Balance(cultItems, 11, set_bal_item11)
  get1155Balance(cultItems, 12, set_bal_item12)
  get1155Balance(cultItems, 13, set_bal_item13)
  get1155Balance(cultItems, 21, set_bal_item21)
  get1155Balance(cultItems, 22, set_bal_item22)
  get1155Balance(cultItems, 31, set_bal_item31)
}

const [isControlsOpen, setIsControlsOpen] = React.useState(false)
const toggleControls = () => {
  if (isControlsOpen) {
    setIsControlsOpen(false)
  } else {
    setIsControlsOpen(true)
  }
}

const [isInventoryOpen, setIsInventoryOpen] = React.useState(false)
const [is1155InventoryOpen, setIs1155InventoryOpen] = React.useState(false)
const toggle = (state, setState) => {
  if (state) {
    setState(false)
  } else {
    setState(true)
  }
}

const toggleInventory = () => {
  console.log("toggling inventory", (isInventoryOpen) ? "off" : "on")
  if (isInventoryOpen) {
    setIsInventoryOpen(false)
    console.log("inventory is toggled off")
  } else {
    setIsInventoryOpen(true)
    if (is1155InventoryOpen) {
      setIs1155InventoryOpen(false)
    }
    console.log("inventory is toggled on")
    console.log("and the state is ", isInventoryOpen)
  }
}
const toggle1155Inventory = () => {
  console.log("toggling 1155 inventory", (is1155InventoryOpen) ? "off" : "on")
  if (is1155InventoryOpen) {
    setIs1155InventoryOpen(false)
    console.log("1155 inventory is toggled off")
  } else {
    setIs1155InventoryOpen(true)
    if (isInventoryOpen) {
      setIsInventoryOpen(false)
    }
    console.log("1155 inventory is toggled on")
    console.log("and the state is ", is1155InventoryOpen)
  }
}

const [activeDialogIndex, setActiveDialogIndex] = React.useState("start")
const [isDialogOpen, setIsDialogOpen] = React.useState(false)
const [isStoreOpen, setIsStoreOpen] = React.useState(false)
const toggleStore = () => {
  console.log("toggling store", (isStoreOpen) ? "off" : "on")
  if (isStoreOpen) {
    setIsStoreOpen(false)
    setIsDialogOpen(true)
    console.log("store is toggled off")
  } else {
    setIsStoreOpen(true)
    setIsDialogOpen(false)
    console.log("store is toggled on")
    console.log("and the state is ", isStoreOpen)
  }
}

const [checkedPreOptions, setCheckedPreOptions] = React.useState(false)

const preCheckFinish = () => {
            setCheckedPreOptions(true)
            setIsModalOpen(false)
          }
let M
React.useEffect(()=>{
  if (!checkedPreOptions && isDialogOpen) {
    console.log("checking pre-options")
    if (story[activeDialogIndex].hasOwnProperty("pre-option")) {

      if (story[activeDialogIndex]["pre-option"] == "briefcase") {
        if (bal_item11 + bal_item12 + bal_item13 > 0) {
          setCheckedPreOptions(true)
        } else {
          setIsModalOpen(true)
          setModalID("11")
          setModalText("You need to mint a briefcase for 1 $CANDLE to continue the story")
          console.log("triggering mint 1155")
          M = mint1155(11, preCheckFinish)
          M()
        }
      } else if (story[activeDialogIndex]["pre-option"] == "apple" ) {
        if (bal_item1 + bal_item2 > 0) {
          setCheckedPreOptions(true)
        } else {
          setIsModalOpen(true)
          setModalID("1")
          setModalText("You need to mint an apple for 1 $CANDLE to continue the story.")
          M = mint1155(1, preCheckFinish)
          M()
        }
      } else if (story[activeDialogIndex]["pre-option"] == "key") {
        console.log(bal_item21, "key balance")
        console.log(bal_item22, "bone key balance")
        if (bal_item21 + bal_item22 > 0) {
          setCheckedPreOptions(true)
        } else {
          setIsModalOpen(true)
          setModalID("21")
          setModalText("You are minting a key for 1 $CANDLE.")
          M = mint1155(21, preCheckFinish)
          M()
        }
      }
    } else {
      setCheckedPreOptions(true)
    }
  }
}, [activeDialogIndex, isDialogOpen])


const goToDialog = (n) => {
  return () => {
    setCheckedPreOptions(false)
    if (n == "pool_cult") {
      setIsDialogOpen(false)
      setIsPoolOpen_Cult(true)
    } else if (n == "pool_xcult") {
      setIsDialogOpen(false)
      setIsPoolOpen_xCult(true)
    } else if (n == "pool_candle") {
      setIsDialogOpen(false)
      setIsPoolOpen_Candle(true)
    } else if (n == "harvestMany") {
      harvestMany()
      setCheckedPreOptions(true)
    } else if (n == "stay") {
      setCheckedPreOptions(true)
    } else {
      setActiveDialogIndex(n)
    }
    
  }
}

const [isPoolOpen_Cult, setIsPoolOpen_Cult] = React.useState(false)
const [isPoolOpen_xCult, setIsPoolOpen_xCult] = React.useState(false)
const [isPoolOpen_Candle, setIsPoolOpen_Candle] = React.useState(false)

  const openInputViewAndDoStuff = (pid, intent) => {
  
    let theAddy = tokens[pools[pid]]["address"]
    let theName = tokens[pools[pid]]["name"]
    let thePool = pid
    return () => {

      toggleInput()
      setTheTargetADDY(theAddy)
      setTheInputINTENT(intent)
      setTheTargetNAME(theName)
      setTheTargetPOOL(thePool)
      farm_gate.getBalance(theAddy, (bal) => smartSetBalanceOfTarget(bal))
      farm_gate.checkAllowance(theAddy, (allowance) => smartSetAllowanceOfTarget(allowance))
    }

  }


    // onClick function factory that returns appropriate harvest function
  const returnHarvestFunction = (id) => {
    return () => {
      setIsPending(true)
      farm_gate.harvest(
        id,
        () => {
          setIsPending(false)
          //update pending rewards
          let f = getPending(id)
          f()
        })
    }     
  }

  const _f = (str) => {
    return str.substring(0, str.length - 18)
  }

  const _g = (str) => {
    if (typeof str != "string") {

    } else {
      if (str.length - 18 > 0) {
        return Number(str.substring(0, str.length - 18))
      } else {
        return Number(str.substring(0, str.length - 15))/1000;
      }
    }  
  }

  const _precise = (str) => {
    if (typeof str != "string") {

    } else {
      //console.log("precise: ", str)
      //console.log("precise substring: ", str.substring(0, str.length - 12))
      //console.log("precise Number: ", Number(str.substring(0, str.length - 12)))
      //console.log("precise Number Proper: ", Number(str.substring(0, str.length - 12))/(1e6) )
      return Number(str.substring(0, str.length - 12))/(1e6);
      
    }  
  }

  const _u = (str) => {
    if (typeof str != "string") {

    } else {
      if (str.length - 6 > 0) {
        return Number(str.substring(0, str.length - 6))
      } else {
        return Number(str.substring(0, str.length - 3))/1000;
      }
    }  
  }



  const [checkedCult, setCheckedCult] = React.useState(false)
  const [checkedxCult, setCheckedxCult] = React.useState(false)
  const [checkedCandle, setCheckedCandle] = React.useState(false)
  const [ifThreeThenGo, setIfThreeThenGo] = React.useState(0)

  React.useEffect(()=>{
    let x,y,z
    if (isPoolOpen_Cult) {
      if (!checkedCult) {
        setCheckedCult(true)
        setIfThreeThenGo(ifThreeThenGo + 1)
      }
      x = getUserInfo(0)
      x()
      y = getPending(0)
      y()
    }
  },[isPoolOpen_Cult])

  React.useEffect(()=>{
    let x,y,z
    if (isPoolOpen_xCult) {
      if (!checkedxCult) {
        setCheckedxCult(true)
        setIfThreeThenGo(ifThreeThenGo + 1)
      }
      x = getUserInfo(1)
      x()
      y = getPending(1)
      y()
    }
  },[isPoolOpen_xCult])

  React.useEffect(()=>{
    let x,y,z
    if (isPoolOpen_Candle) {
      if (!checkedCandle) {
        setCheckedCandle(true)
        setIfThreeThenGo(ifThreeThenGo + 1)
      }
      x = getUserInfo(2)
      x()
      y = getPending(2)
      y()
    }
  },[isPoolOpen_Candle])



  const mint1155 = (itemId, callback = () => {}) => {
    return () => {
      console.log("checking Cult Items $CANDLE allowance")
      setIsPending(true)
      candle.methods.allowance(
        window.ethereum.selectedAddress,
        nfts["items"]["address"]
        ).call({from: window.ethereum.selectedAddress})
        .then((allw) => {
          if (allw.toString().length < 22) {
             candle.methods.approve(nfts["items"]["address"], "1000000000000000000000000")
              .send({from: window.ethereum.selectedAddress})
              .then((res0) => {
                cultItems.methods.mintItem(itemId)
                  .send({from: window.ethereum.selectedAddress})
                  .then((res) => {
                    setIsPending(false)
                    callback()
                  }).catch((err)=>{
                    setIsPending(false)
                  })
              }).catch((err)=>{
                    setIsPending(false)
                  })
          } else {
            cultItems.methods.mintItem(itemId)
              .send({from: window.ethereum.selectedAddress})
              .then((res) => {
                setIsPending(false)
                callback()
              }).catch((err)=>{
                setIsPending(false)
              })
          }
        })
      
    }
  }

  const [minted_item1, set_minted_item1] = React.useState(0)
  const [minted_item2, set_minted_item2] = React.useState(0)
  const [minted_item11, set_minted_item11] = React.useState(0)
  const [minted_item12, set_minted_item12] = React.useState(0)
  const [minted_item13, set_minted_item13] = React.useState(0)
  const [minted_item21, set_minted_item21] = React.useState(0)
  const [minted_item22, set_minted_item22] = React.useState(0)
  const [minted_item31, set_minted_item31] = React.useState(0)

  const getMintedAmount = (id, setState) => {
    cultItems.methods.getItem(id)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        console.log(res["6"])
        setState(res["6"])
      })
  }

  React.useEffect(()=>{
    if (isStoreOpen) {
      getMintedAmount(1, set_minted_item1)
      getMintedAmount(2, set_minted_item2)
      getMintedAmount(11, set_minted_item11)
      getMintedAmount(12, set_minted_item12)
      getMintedAmount(13, set_minted_item13)
      getMintedAmount(21, set_minted_item21)
      getMintedAmount(22, set_minted_item22)
      getMintedAmount(31, set_minted_item31)

    }
  },[isStoreOpen])

  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [modalText, setModalText] = React.useState("")
  const [modalID, setModalID] = React.useState("1")

  const harvestMany = () => {
    let x = [], i = 0


    if (_g(userInfo0['0']) > 0) {
      x[0] = 0
      i++
    }
    if (_g(userInfo1['0']) > 0) {
      x[i] = 1
      i++
    }
    if (_g(userInfo2['0']) > 0) {
      x[i] = 2
      i++
    }

    setIsPending(true)
    console.log(x)
    farm.methods.harvestMany(x)
      .send({from: window.ethereum.selectedAddress})
      .then((res)=>{
        setIsPending(false)
      }).catch((err)=>{
        setIsPending(false)
      })
  }


const claimByIdEstimate = (tract, id, cb) => {
  tract.methods.claimById(id).estimateGas({
    from: window.ethereum.selectedAddress
  }, function(error, estimatedGas) {
    if (error != null) {
      cb()
    } else {
      setIsPending(true)
      cult.methods.claimById(id)
        .send({from: window.ethereum.selectedAddress})
        .then((res)=>{
          setIsPending(false)
          if (skullyIndex < balSkully - 1) {
            getSkullyId(skullyIndex + 1)
            setSkullyIndex(skullyIndex + 1)
          }
        }).catch((err)=>{
          console.log(err)
          setIsPending(false)
        })
    }
    
  })
}
const claimByPopSkullyIdEstimate = (id, cb) => {
  xcult.methods.claimByPopSkullyId(id).estimateGas({
    from: window.ethereum.selectedAddress
  }, function(error, estimatedGas) {
    if (error != null) {
      cb()
    } else {
      setIsPending(true)
      xcult.methods.claimByPopSkullyId(id)
        .send({from: window.ethereum.selectedAddress})
        .then((res)=>{
          setIsPending(false)
          if (popskullyIndex < balPopSkully - 1) {
            getPopSkullyId(popskullyIndex + 1)
            setPopSkullyIndex(popskullyIndex + 1)
          }
        }).catch((err)=>{
          console.log(err)
          setIsPending(false)
        })
    }
    
  })
}
const claimByGhostlyIdEstimate = (id, cb) => {
  xcult.methods.claimByGhostlyId(id).estimateGas({
    from: window.ethereum.selectedAddress
  }, function(error, estimatedGas) {
    if (error != null) {
      cb()
    } else {
      setIsPending(true)
      xcult.methods.claimByGhostlyId(id)
        .send({from: window.ethereum.selectedAddress})
        .then((res)=>{
          setIsPending(false)
          if (ghostlyIndex < balGhostly - 1) {
            getGhostlyId(ghostlyIndex + 1)
            setGhostlyIndex(ghostlyIndex + 1)
          }
        }).catch((err)=>{
          console.log(err)
          setIsPending(false)
        })
    }
  
  })
}
const claimAllEstimate = (tract, cb) => {
  tract.methods.claimAllForOwner().estimateGas({
    from: window.ethereum.selectedAddress
  }, function(error, estimatedGas) {
    if (error != null) {
      cb()
    } else {
      setIsPending(true)
      tract.methods.claimAllForOwner()
        .send({from: window.ethereum.selectedAddress})
        .then((res)=>{
          setIsPending(false)
        })
    }
  })
}
const claimAll = () => {


  cult.methods.claimAllForOwner()
    .send({from: window.ethereum.selectedAddress})
    .then((res)=>{
      
    }).catch((err)=>{
      alert("you already claimed $CULT for at least one of your Skullys. Use the contract on ftmscan.com to claim for individual Skullys.")
      
    })
}

const [isToolsOpen, setIsToolsOpen] = React.useState(false)
const toggleTools = () => {
  if (isToolsOpen) {
    setIsToolsOpen(false)
  } else {
    setIsToolsOpen(true)
  }
}

const [isBFOpen, setIsBFOpen] = React.useState(false)
const [isBFDeposit, setIsBFDeposit] = React.useState(true)
const [isBFWithdraw, setIsBFWithdraw] = React.useState(false)
const [isBFBurn, setIsBFBurn] = React.useState(false)
const [isBFInfo, setIsBFInfo] = React.useState(false)
const toggleBF = () => {
  if (isBFOpen) {
    setIsBFOpen(false)
  } else {
    setIsBFOpen(true)
  }
}

const changeActiveBF = (bfScreen) => {
  if (bfScreen == 0){
    setIsBFDeposit(true)
    setIsBFWithdraw(false)
    setIsBFBurn(false)
    setIsBFInfo(false)
  } else if (bfScreen == 1) {
    setIsBFDeposit(false)
    setIsBFWithdraw(true)
    setIsBFBurn(false)
    setIsBFInfo(false)
  } else if (bfScreen == 2) {
    setIsBFDeposit(false)
    setIsBFWithdraw(false)
    setIsBFBurn(true)
    setIsBFInfo(false)
  } else {
    setIsBFDeposit(false)
    setIsBFWithdraw(false)
    setIsBFBurn(false)
    setIsBFInfo(true)
  }
}

const [skullyIndex, setSkullyIndex] = React.useState(0)
const [skully0, setSkully0] = React.useState(1001);
const getSkullyId = (index) => {
  console.log("getting skully index: ", index)
    console.log("for user with balance: ", balSkully)
  if (index < balSkully) {

    skullys.methods.tokenOfOwnerByIndex(window.ethereum.selectedAddress, index)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        setSkully0(res)
      })
  }
}

const [popskullyIndex, setPopSkullyIndex] = React.useState(0)
const [popskully0, setPopSkully0] = React.useState(5555)
const getPopSkullyId = (index) => {
  console.log("getting pop skully index: ", index)
    console.log("for user with balance: ", balPopSkully)
  if (index < balPopSkully) {

    popskullys.methods.tokenOfOwnerByIndex(window.ethereum.selectedAddress, index)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        setPopSkully0(res)
      })
  }
}

const [ghostlyIndex, setGhostlyIndex] = React.useState(0)
const [ghostly0, setGhostly0] = React.useState(9999);
const getGhostlyId = (index) => {
  console.log("getting ghostly index: ", index)
    console.log("for user with balance: ", balGhostly)
  if (index < balGhostly) {

    ghostlys.methods.tokenOfOwnerByIndex(window.ethereum.selectedAddress, index)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        setGhostly0(res)
      })
  }
}


React.useEffect(()=>{

  if (skully0 == 1001 && isToolsOpen) {
    getSkullyId(0)
  }
  if (popskully0 == 5555 && isToolsOpen) {
    getPopSkullyId(0)
  }
  if (ghostly0 == 9999 && isToolsOpen) {
    getGhostlyId(0)
  }
}, [isToolsOpen])

const claimByPopSkullyIdLoop = () => {
  claimByPopSkullyIdEstimate(
    popskully0,
    () => {
      if (popskullyIndex < balPopSkully - 1) {
        getPopSkullyId(popskullyIndex + 1)
        setPopSkullyIndex(popskullyIndex + 1)
      }
    }
    )
}

const claimByGhostlyIdLoop = () => {
  claimByGhostlyIdEstimate(
    ghostly0,
    () => {
      if (ghostlyIndex < balGhostly - 1) {
        getGhostlyId(ghostlyIndex + 1)
        setGhostlyIndex(ghostlyIndex + 1)
      }
    }
    )
}

const claimByIdLoop = () => {
  claimByIdEstimate(
    cult,
    skully0,
    () => {
      if (skullyIndex < balSkully - 1) {
        getSkullyId(skullyIndex + 1)
        setSkullyIndex(skullyIndex + 1)
      }
    }
    )
}

const mintPopSkully = () => {
  setIsPending(true)
  popskullys.methods.mint("0x0000000000000000000000000000000000000000", 1)
    .send({from: window.ethereum.selectedAddress, value: "50".concat("0".repeat(18))})
    .then((res) =>{
      setIsPending(false)
    }).catch((err)=>{
      setIsPending(false)
    })

}

const passwordRef = React.createRef()

const hasClaimed_SkullyRef = React.createRef()
const [hasClaimedMessage, setHasClaimedMessage] = React.useState("--")
const getHasClaimed_Skully = (id) => {
  if (id.current.hasOwnProperty("value")) {
    cult.methods.seasonClaimedByTokenId(0, Number(id.current.value))
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        if (res) {
          setHasClaimedMessage("Skully #" + id.current.value + " has claimed their $CULT")
        } else {
          
          setHasClaimedMessage("Skully #" + id.current.value + " has NOT claimed their $CULT")
        
        }
      })
  }
}

const hasClaimed_PopSkullyRef = React.createRef()
const [hasClaimedMessage_Pop, setHasClaimedMessage_Pop] = React.useState("--")
const getHasClaimed_PopSkully = (id) => {
  if (id.current.hasOwnProperty("value")) {
    xcult.methods.seasonClaimedByPopSkullyId(0, Number(id.current.value))
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        if (res) {
          setHasClaimedMessage_Pop("Pop Skully #" + id.current.value + " has claimed their $xCULT")
        } else {
          
          setHasClaimedMessage_Pop("Pop Skully #" + id.current.value + " has NOT claimed their $xCULT")
        
        }
      })
  }
}

const hasClaimed_GhostlyRef = React.createRef()
const [hasClaimedMessage_Ghostly, setHasClaimedMessage_Ghostly] = React.useState("--")
const getHasClaimed_Ghostly = (id) => {
  if (id.current.hasOwnProperty("value")) {
    xcult.methods.seasonClaimedByGhostlyId(0, Number(id.current.value))
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        if (res) {
          setHasClaimedMessage_Ghostly("Ghostly #" + id.current.value + " has claimed their $xCULT")
        } else {
          setHasClaimedMessage_Ghostly("Ghostly #" + id.current.value + " has NOT claimed their $xCULT")
        }
      })
  }
}

const [skullyFloor, setSkullyFloor] = React.useState("")
const [skullyFloorID, setSkullyFloorID] = React.useState(0)
const [popskullyFloor, setPopSkullyFloor] = React.useState("")
const [popskullyFloorID, setPopSkullyFloorID] = React.useState(0)
const [ghostlyFloor, setGhostlyFloor] = React.useState("")
const [ghostlyFloorID, setGhostlyFloorID] = React.useState(0)
const getPaintswapData = (collection, setID, setFloor) => {
  var a = "https://api.paintswap.finance/v2/sales?"
  var b = "numToFetch=1&orderBy=price&sold=false&includeAuctions=false&"
  var c = "collections=" + nfts[collection]["address"]
  fetch(a + b + c)
    .then((response) => response.json())
    .then((json) => {
      setID(json.sales[0].tokenId)
      setFloor(_g(json.sales[0].price))
      console.log(json)
    })
}

const _lp = (pair) => {
  return new web3.eth.Contract(lps[pair]["abi"], lps[pair]["address"]);
}
const [reserves_soulusdc, setReserves_soulusdc] = React.useState([1,1])
const [reserves_cultsoul, setReserves_cultsoul] = React.useState([1,1])
const [reserves_xcultsoul, setReserves_xcultsoul] = React.useState([1,1])
const [reserves_candlesoul, setReserves_candlesoul] = React.useState([1,1])
const [reserves_ftmusdc, setReserves_ftmusdc] = React.useState([1,1])
const [reserves_candleusdc, setReserves_candleusdc] = React.useState([1,1])
const [reserves_cultftm, setReserves_cultftm] = React.useState([1,1])
const [reserves_cultxcult, setReserves_cultxcult] = React.useState([1,1])
const [reserves_xcultftm, setReserves_xcultftm] = React.useState([1,1])
const getReserves = (pair, cb = () => {}) => {
  var x = _lp(pair)

  x.methods.getReserves()
    .call({from: window.ethereum.selectedAddress})
    .then((res) => {
      let r0 = fromWei(res._reserve0)
      if (pair == "soul-usdc" || pair=="wftm-usdc" || pair=="candle-usdc") {
        r0 = (Number(res._reserve0)/1e6).toFixed(6)
      }
      let r1 = fromWei(res._reserve1)
      //console.log(r0)
      //console.log(r1)
      cb(r0,r1)
      //console.log(r1/r0, lps[pair]["tokens"][1] + " per " + lps[pair]["tokens"][0])
    })
}
const getFtmUsdc = () => {
  getReserves("wftm-usdc", (r0,r1)=>{
    setReserves_ftmusdc([r0,r1])  
  })
}
const getSoulUsdc = () => {
  getReserves("soul-usdc", (r0,r1)=>{
    setReserves_soulusdc([r0,r1])
  })
}

const getCultSoul = () => {
  getReserves("cult-soul", (r0,r1)=>{
    setReserves_cultsoul([r0,r1])
  })
}

const getxCultSoul = () => {
  getReserves("xcult-soul", (r0,r1)=>{
    setReserves_xcultsoul([r0,r1])
  })
}

const getCandleSoul = () => {
  getReserves("candle-soul", (r0,r1)=>{
    setReserves_candlesoul([r0,r1])
  })
}

const getCandleUsdc = () => {
  getReserves("candle-usdc", (r0,r1)=>{
    setReserves_candleusdc([r0,r1])
  })
}
const getCultFtm = ()=> {
  getReserves("cult-ftm", (r0,r1)=>{
    setReserves_cultftm([r0,r1])
  })
}
const getxCultFtm = ()=> {
  getReserves("xcult-ftm", (r0,r1)=>{
    setReserves_xcultftm([r0,r1])
  })
}

const getCultxCult = ()=> {
  getReserves("cult-xcult", (r0,r1)=>{
    setReserves_cultxcult([r0,r1])
  })
}

const [totalSupply_cult, setTotalSupply_cult] = React.useState(0)
const [totalSupply_lp_cult, setTotalSupply_lp_cult] = React.useState(0)
const [totalSupply_slp_cult, setTotalSupply_slp_cult] = React.useState(0)
const getCultSoulUsdc = () => {
    getFtmUsdc()
    getSoulUsdc()
    getCultSoul()
    getCultFtm()
    getTotalSupply(cult,setTotalSupply_cult)
    getCultxCult()
    getTotalSupply(lp_cultxcult, setTotalSupply_lp_cultxcult)
    getTotalSupply(slp_cult,setTotalSupply_slp_cult)
}
const [totalSupply_xcult, setTotalSupply_xcult] = React.useState(0)
const [totalSupply_lp_xcult, setTotalSupply_lp_xcult] = React.useState(0)
const [totalSupply_slp_xcult, setTotalSupply_slp_xcult] = React.useState(0)
const [totalSupply_lp_cultxcult, setTotalSupply_lp_cultxcult] = React.useState(0)
const getxCultSoulUsdc = () => {
    getSoulUsdc()
    getxCultSoul()
    getxCultFtm()
    getFtmUsdc()
    getTotalSupply(xcult,setTotalSupply_xcult)
 
    getTotalSupply(slp_xcult,setTotalSupply_slp_xcult)
}
const [totalSupply_candle, setTotalSupply_candle] = React.useState(0)
const [totalSupply_lp_candle, setTotalSupply_lp_candle] = React.useState(0)
const [totalSupply_lp_candleusdc, setTotalSupply_lp_candleusdc] = React.useState(0)
const getCandleSoulUsdc = () => {
    getSoulUsdc()
    getCandleSoul()
    getCandleUsdc()
    getTotalSupply(candle,setTotalSupply_candle)
    getTotalSupply(lp_candleusdc, setTotalSupply_lp_candleusdc)
    getGateBal(candle, setBal_gate_candle)
    getDgnBal(candle, setBal_dgn_candle)
    getDeadBal(candle, setBal_dead_candle)
    getMktBal(candle, setBal_mkt_candle)
}

const [price_ftm, setPrice_ftm] = React.useState(false)
React.useEffect(()=>{
  let ftm, usdc
  if (reserves_ftmusdc[0] != 1) {
    usdc = reserves_ftmusdc[0]
    ftm = reserves_ftmusdc[1]
    setPrice_ftm(Math.round((usdc/ftm) * 1000)/1000)
  }
}, [reserves_ftmusdc])

const [price_cult, setPrice_cult] = React.useState(false)
React.useEffect(()=>{
  let c, c1, b1, usdc
  if (reserves_ftmusdc[0] != 1 && reserves_cultftm[0] != 1) {
    c = reserves_cultftm[1]
    c1 = reserves_cultftm[0]
    b1 = reserves_ftmusdc[1]
    usdc = reserves_ftmusdc[0]
    console.log(c, c1, b1, usdc)
    setPrice_cult(Math.round(100*(usdc/b1) * (c1/c))/100)
  }
}, [reserves_cultftm, reserves_ftmusdc])

const [price_xcult, setPrice_xcult] = React.useState(false)
React.useEffect(()=>{
  let c, c1, b1, usdc
  if (reserves_ftmusdc[0] != 1 && reserves_xcultftm[0] != 1) {
    c = reserves_xcultftm[1]
    c1 = reserves_xcultftm[0]
    b1 = reserves_ftmusdc[1]
    usdc = reserves_ftmusdc[0]
    setPrice_xcult(Math.round(100 *(usdc/b1) * (c1/c))/100)
  }
}, [reserves_xcultftm, reserves_ftmusdc])

const [price_candle, setPrice_candle] = React.useState(false)
React.useEffect(()=>{
  let c, usdc
  if (reserves_candleusdc[0] != 1) {
    c = reserves_candleusdc[1]
    usdc = reserves_candleusdc[0]
    console.log(c, usdc)
    setPrice_candle(Math.round((usdc/c) * 1000)/1000)
  }
}, [reserves_candleusdc])

const getTotalSupply = (token,setTotalSupply) => {
  token.methods.totalSupply()
    .call({from: window.ethereum.selectedAddress})
    .then((res)=>{
      //console.log("total supply of", token, fromWei(res))
      setTotalSupply(fromWei(res))
    })
}

const [bal_gate_candle, setBal_gate_candle] = React.useState(0)
const getGateBal = (token,setBal) => {
  token.methods.balanceOf(farms["gate"]["address"])
    .call({from: window.ethereum.selectedAddress})
    .then((res)=>{
      setBal(_g(res))
    })
}

const [bal_dgn_candle, setBal_dgn_candle] = React.useState(0)
const getDgnBal = (token,setBal) => {
  token.methods.balanceOf(degenesis["address"])
    .call({from: window.ethereum.selectedAddress})
    .then((res)=>{
      setBal(_g(res))
    })
}

const [bal_dead_candle, setBal_dead_candle] = React.useState(0)
const getDeadBal = (token,setBal) => {
  token.methods.balanceOf("0x000000000000000000000000000000000000dEaD")
    .call({from: window.ethereum.selectedAddress})
    .then((res)=>{
      setBal(_g(res))
    })
} 
const [bal_mkt_candle, setBal_mkt_candle] = React.useState(0)
const getMktBal = (token,setBal) => {
  token.methods.balanceOf("0xdb7f955805026fa8bb76b87076f51c13105ea531")
    .call({from: window.ethereum.selectedAddress})
    .then((res)=>{
      setBal(_g(res))
    })
}

const _pct = (num, den) =>{
  return (Math.floor(100*num/den)).toString() + "%"
}

const rt = new web3.eth.Contract(routers["Spooky"]["abi"], routers["Spooky"]["address"])
const rtsoul = new web3.eth.Contract(routers["SoulSwap"]["abi"], routers["SoulSwap"]["address"])
const [amtOut, setAmtOut] = React.useState("0")
const swapFTM = (_in,o,_path, _nameIn = "FTM", _nameOut = "", cb = () => {}) => {
  

  rt.methods.swapExactETHForTokens(
        o, //amountOutMin
        _path, // path 
        window.ethereum.selectedAddress, // to 
        (Math.floor(Date.now() / 1000) + 120).toString()).estimateGas({
    from: window.ethereum.selectedAddress, value: _in
  }, function(error, estimatedGas) {
    if (error != null) {
      cb()
    } else {
      setIsPending(true)
      setPendingMessage("swapping " + fromWei(_in) + " FTM for " + fromWei(o) + " "+ _nameOut)
      rt.methods.swapExactETHForTokens(
        o, //amountOutMin
        _path, // path 
        window.ethereum.selectedAddress, // to 
        (Math.floor(Date.now() / 1000) + 120).toString())
        .send({from: window.ethereum.selectedAddress, value: _in})
        .then((res) => {
          console.log("swap response: ", res)
          setIsPending(false)
          setPendingMessage(PENDING_MESSAGE)
          getTokenBalance(cult, setBalCult)
          getTokenBalance(xcult, setBalXCult)
          getTokenBalance(candle, setBalCandle)
        }).catch((err) => {
          console.log(err)
          setIsPending(false)
          setPendingMessage(PENDING_MESSAGE)
        })

    }
  })
}
const soulswapFTM = (_in,o,_path, _nameIn = "FTM", _nameOut = "", cb = () => {}) => {
  

  rtsoul.methods.swapExactETHForTokens(
        o, //amountOutMin
        _path, // path 
        window.ethereum.selectedAddress, // to 
        (Math.floor(Date.now() / 1000) + 120).toString()).estimateGas({
    from: window.ethereum.selectedAddress, value: _in
  }, function(error, estimatedGas) {
    if (error != null) {
      cb()
    } else {
      setIsPending(true)
      setPendingMessage("swapping " + fromWei(_in) + " FTM for " + fromWei(o) + " "+ _nameOut)
      rtsoul.methods.swapExactETHForTokens(
        o, //amountOutMin
        _path, // path 
        window.ethereum.selectedAddress, // to 
        (Math.floor(Date.now() / 1000) + 120).toString())
        .send({from: window.ethereum.selectedAddress, value: _in})
        .then((res) => {
          console.log("swap response: ", res)
          setIsPending(false)
          setPendingMessage(PENDING_MESSAGE)
          getTokenBalance(cult, setBalCult)
          getTokenBalance(xcult, setBalXCult)
          getTokenBalance(candle, setBalCandle)
        }).catch((err) => {
          console.log(err)
          setIsPending(false)
          setPendingMessage(PENDING_MESSAGE)
        })

    }
  })
}
const getAmountsOut = (_in, _path, swapper = () => {}, _nameIn = "", _nameOut = "") => {
  rt.methods.getAmountsOut(_in, _path)
    .call({from: window.ethereum.selectedAddress})
    .then((res)=>{
      //console.log("getAmountsOut response: ", res)
      let o = _slip(res[res.length - 1])
      //console.log("postslip: ", o)
      let tract 
      if (_path[0] != "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83") {
        tract = new web3.eth.Contract(tokens["cult"]["abi"],_path[0])
        tract.methods.allowance(window.ethereum.selectedAddress, routers["Spooky"]["address"])
          .call({from: window.ethereum.selectedAddress})
          .then((res) => {
            //console.log("checked allowance",res,_in)
            if (Number(fromWei(res)) > Number(fromWei(_in))) {
              swapper(_in,o,_path,_nameIn, _nameOut)
            } else {
              setIsPending(true)
              setPendingMessage("approving soulswap to spend " + fromWei(_in) + " " + _nameIn)
              tract.methods.approve(routers["Spooky"]["address"], _in)
                .send({from: window.ethereum.selectedAddress})
                .then((res)=>{
                  setIsPending(false)
                  setPendingMessage(PENDING_MESSAGE)
                  swapper(_in,o,_path,_nameIn, _nameOut)
                }).catch((err)=>{
                  setIsPending(false)
                  setPendingMessage(PENDING_MESSAGE)
                  console.log(err)
                })
            }
          }).catch((err) => {
            console.log(err)
          })
      } else {
        swapper(_in,o,_path,_nameIn, _nameOut)
      }
      
      
    }).catch((err)=>{
      console.log("error in getAmountsOut", err)
    })
}
const getAmountsOutX = (_in, _path, swapper = () => {}, _nameIn = "", _nameOut = "") => {
  rt.methods.getAmountsOut(_in, _path)
    .call({from: window.ethereum.selectedAddress})
    .then((res)=>{
      //console.log("getAmountsOut response: ", res)
      let o = _slip(res[res.length - 1])
      //console.log("postslip: ", o)
      let tract 
      if (_nameIn != "wftm") {
        tract = new web3.eth.Contract(tokens["cult"]["abi"],_path[0])
        tract.methods.allowance(window.ethereum.selectedAddress, routers["Spooky"]["address"])
          .call({from: window.ethereum.selectedAddress})
          .then((res) => {
            //console.log("checked allowance",res,_in)
            if (Number(fromWei(res)) > Number(fromWei(_in))) {
              swapper(_in,o,_path,_nameIn, _nameOut)
            } else {
              setIsPending(true)
              setPendingMessage("approving soulswap to spend " + fromWei(_in) + " " + _nameIn)
              tract.methods.approve(routers["Spooky"]["address"], _in)
                .send({from: window.ethereum.selectedAddress})
                .then((res)=>{
                  setIsPending(false)
                  setPendingMessage(PENDING_MESSAGE)
                  swapper(_in,o,_path,_nameIn, _nameOut)
                }).catch((err)=>{
                  setIsPending(false)
                  setPendingMessage(PENDING_MESSAGE)
                  console.log(err)
                })
            }
          }).catch((err) => {
            console.log(err)
          })

      } else {
        swapFTM(_in,o,_path,_nameIn, _nameOut)
      }
      
      
      
    }).catch((err)=>{
      console.log("error in getAmountsOut", err)
    })
}
const getAmountsOutXsoul = (_in, _path, swapper = () => {}, _nameIn = "", _nameOut = "") => {
  rtsoul.methods.getAmountsOut(_in, _path)
    .call({from: window.ethereum.selectedAddress})
    .then((res)=>{
      //console.log("getAmountsOut response: ", res)
      let o = _slip(res[res.length - 1])
      //console.log("postslip: ", o)
      let tract 
      if (_nameIn != "wftm") {
      tract = new web3.eth.Contract(tokens["cult"]["abi"],_path[0])
      tract.methods.allowance(window.ethereum.selectedAddress, routers["Spooky"]["address"])
        .call({from: window.ethereum.selectedAddress})
        .then((res) => {
          //console.log("checked allowance",res,_in)
          if (Number(fromWei(res)) > Number(fromWei(_in))) {
            swapper(_in,o,_path,_nameIn, _nameOut)
          } else {
            setIsPending(true)
            setPendingMessage("approving soulswap to spend " + fromWei(_in, _nameIn) + " " + _nameIn)
            tract.methods.approve(routers["Spooky"]["address"], _in)
              .send({from: window.ethereum.selectedAddress})
              .then((res)=>{
                setIsPending(false)
                setPendingMessage(PENDING_MESSAGE)
                swapper(_in,o,_path,_nameIn, _nameOut)
              }).catch((err)=>{
                setIsPending(false)
                setPendingMessage(PENDING_MESSAGE)
                console.log(err)
              })
          }
        }).catch((err) => {
          console.log(err)
        })
      } else {
        soulswapFTM(_in,o,_path,_nameIn, _nameOut)
      }
      
      
      
    }).catch((err)=>{
      console.log("error in getAmountsOut", err)
    })
}

const sPath = (toTokenName) => {
  let x
  if (toTokenName == "candle") {
    x = [
      tokens["wftm"]["address"],
      tokens["usdc"]["address"],
      tokens[toTokenName]["address"]
    ]
  } else {
    x = [
      tokens["wftm"]["address"],
      tokens[toTokenName]["address"]
    ]
  }
  return x
}
const _Path = (_from, _to) => {
  let _f1, _t1, n = 0, x
  if (_from == "candle") {
    _f1 = "usdc"
    _t1 = "wftm"
    n = 1
  } 
  if (_to == "candle") {
    _t1 = "usdc"
    _f1 = "wftm"
    n = 1
  }

  if (n == 1) {
    x = [
      tokens[_from]["address"],
      tokens[_f1]["address"],
      tokens[_t1]["address"],
      tokens[_to]["address"]
    ]
  } else {
    x = [
      tokens[_from]["address"],
      tokens["wftm"]["address"],
      tokens[_to]["address"]
    ]
  }
  return x
}


const _slip = (amt) => {
  //console.log("preslip: ", amt)
  //console.log("preslip clean amt: ", Number(_precise(amt)))
  //console.log("preslip clean slippage: ", Number(_precise(amt)) / 100)
 
  return web3.utils.toWei( (Number(_precise(amt)) - ( Number(_precise(amt)) / 100 ) ).toString(), 'ether')
}


const swapExactFTM = (_in, _name, swapper) => {
  getAmountsOut(_in, sPath(_name), swapper, "FTM", _name)
}

const swapNftm = (n, _name) => {
  getAmountsOut(web3.utils.toWei(n.toString(), "ether"), sPath(_name), swapFTM, "FTM", _name)
}

const swapToke4Toke = (_in,o,_path, _nameIn = "", _nameOut = "", cb = ()=> {}) => {
  
  console.log(fromWei(_in, _nameIn))
  console.log(fromWei(o, _nameOut))
  rt.methods.swapExactTokensForTokens(_in,
        o, //amountOutMin
        _path, // path 
        window.ethereum.selectedAddress, // to 
        (Math.floor(Date.now() / 1000) + 120).toString()).estimateGas({
    from: window.ethereum.selectedAddress
  }, function(error, estimatedGas) {
    if (error != null) {
      cb()
    } else {
      setIsPending(true)
      setPendingMessage("swapping " + fromWei(_in, _nameIn) + " " + 
        _nameIn + " for " + fromWei(o, _nameOut) + " " + _nameOut)
      rt.methods.swapExactTokensForTokens(
        _in,
        o, //amountOutMin
        _path, // path 
        window.ethereum.selectedAddress, // to 
        (Math.floor(Date.now() / 1000) + 120).toString())
        .send({from: window.ethereum.selectedAddress})
        .then((res) => {
          console.log("swap response: ", res)
          setIsPending(false)
          setPendingMessage(PENDING_MESSAGE)
          getTokenBalance(cult, setBalCult)
          getTokenBalance(xcult, setBalXCult)
          getTokenBalance(candle, setBalCandle)
        }).catch((err) => {
          console.log(err)
          setIsPending(false)
          setPendingMessage(PENDING_MESSAGE)
        })
    }
  })
  
}
const soulswapToke4Toke = (_in,o,_path, _nameIn = "", _nameOut = "", cb = ()=> {}) => {
  
  console.log(fromWei(_in, _nameIn))
  console.log(fromWei(o, _nameOut))
  rtsoul.methods.swapExactTokensForTokens(_in,
        o, //amountOutMin
        _path, // path 
        window.ethereum.selectedAddress, // to 
        (Math.floor(Date.now() / 1000) + 120).toString()).estimateGas({
    from: window.ethereum.selectedAddress
  }, function(error, estimatedGas) {
    if (error != null) {
      cb()
    } else {
      setIsPending(true)
      setPendingMessage("swapping " + fromWei(_in, _nameIn) + " " + 
        _nameIn + " for " + fromWei(o, _nameOut) + " " + _nameOut)
      rtsoul.methods.swapExactTokensForTokens(
        _in,
        o, //amountOutMin
        _path, // path 
        window.ethereum.selectedAddress, // to 
        (Math.floor(Date.now() / 1000) + 120).toString())
        .send({from: window.ethereum.selectedAddress})
        .then((res) => {
          console.log("swap response: ", res)
          setIsPending(false)
          setPendingMessage(PENDING_MESSAGE)
          getTokenBalance(cult, setBalCult)
          getTokenBalance(xcult, setBalXCult)
          getTokenBalance(candle, setBalCandle)
        }).catch((err) => {
          console.log(err)
          setIsPending(false)
          setPendingMessage(PENDING_MESSAGE)
        })
    }
  })
  
}

const swapT4T = (n, _from, _to) => {
  getAmountsOut(web3.utils.toWei(n.toString(), "ether"), _Path(_from,_to), swapToke4Toke, _from, _to)
}

const swapT4Tp = (n, _path, _from, _to) => {
  getAmountsOutX(toWei(n, _from), _path, swapToke4Toke, _from, _to)
}
const swapT4Tpsoul = (n, _path, _from, _to) => {
  getAmountsOutXsoul(toWei(n, _from), _path, soulswapToke4Toke, _from, _to)
}
const [isFoliOpen, setIsFoliOpen] = React.useState(false)
const [hasFetchedAll, setHasFetchedAll] = React.useState(false)
const toggleFoli = () => {
  if (isFoliOpen) {
    setIsFoliOpen(false)
  } else {
    if (!hasFetchedAll) {
      readyFoli()
      setHasFetchedAll(true)
    }
    setIsFoliOpen(true)

  }
}

const fromWei = (wei, _name = "") => {
  if (_name == "usdc") {
    return (wei/1e6).toString()
  } else {
    return Number(web3.utils.fromWei(wei.toString(),"ether"))
  }
  
}

const toWei = (n, _name = "") => {
  n = Number(n)
  if (_name == "usdc") {
    return (n * 1e6).toString()
  } else {
    return web3.utils.toWei(n.toString(),"ether")
  }
  
}

const getNetWorth = (_info, _bal, _price) => {
  if (_info.hasOwnProperty('0')) {
    return (
      (
        Number(fromWei(_info['0'])) + Number(fromWei(_bal))
      ) * _price )
  } else {
    return 0
  }
}



const lpValue = (_amt, _total, _reserves, _price) => {
  //console.log(_amt, _total, _reserves, _price)
  return 2 * _price * _reserves * _amt / _total 
}

const bf_lpValue = (_reserves, _price) => {
  console.log(_reserves, _price)
  return 2 * _price * _reserves 
}



const readyFoli = () => {
  getFtmUsdc()
  getSoulUsdc()
  getCultSoul()
  getTotalSupply(cult,setTotalSupply_cult)
  getTotalSupply(slp_cult,setTotalSupply_slp_cult)
  getxCultSoul()
  getTotalSupply(xcult,setTotalSupply_xcult)
  getTotalSupply(slp_xcult,setTotalSupply_slp_xcult)
  getCandleSoul()
  getCandleUsdc()
  getCultFtm()
  getxCultFtm()
  getTotalSupply(candle,setTotalSupply_candle)
  getTotalSupply(lp_candleusdc, setTotalSupply_lp_candleusdc)
  getGateBal(candle, setBal_gate_candle)
  getDgnBal(candle, setBal_dgn_candle)
  getDeadBal(candle, setBal_dead_candle)
  getMktBal(candle, setBal_mkt_candle)

  getPoolLength()
  get_bfPoolLength()
  getPaintswapData("skullys", setSkullyFloorID, setSkullyFloor)
  getPaintswapData("popskullys", setPopSkullyFloorID, setPopSkullyFloor)
  getPaintswapData("ghostlys", setGhostlyFloorID, setGhostlyFloor)
  
}

React.useEffect(() => {
  if (userInfo0.hasOwnProperty(['0']) && userInfo1.hasOwnProperty(['0']) && userInfo2.hasOwnProperty(['0'])) {
    calcAndAddData()
  }
}, [userInfo0, userInfo1, userInfo2])

const [chartData, setChartData] = React.useState([])
const addData = (_name, _value, _state, _setState) => {
  let x = _state
  x[x.length] = {name: _name, value: _value}
  _setState(x)
}


const [datapoint_LP, setDataPoint_LP] = React.useState(0)

const [datapoint_LP_cult, setDataPoint_LP_cult] = React.useState(0)
const [datapoint_LP_xcult, setDataPoint_LP_xcult] = React.useState(0)
const [datapoint_LP_candle, setDataPoint_LP_candle] = React.useState(0)
const [datapoint_LP_candleusdc, setDataPoint_LP_candleusdc] = React.useState(0)
const [datapoint_LP_cultxcult, setDataPoint_LP_cultxcult] = React.useState(0)
const getSingleDataPoint_LP = (_bal, _supply, _reserves, _price) => {
  return lpValue(fromWei(_bal), _supply, _reserves, _price).toFixed(2)
}

const setDP = (bf_userInfo, bal_lp, price, totalSupply, reserves, reservesIndex, setDataPoint) => {
  let x, y
  let u = (bf_userInfo.hasOwnProperty('amount')) ? bf_userInfo.amount : 0
  if ((bal_lp > 0 || Number(fromWei(u)) > 0) && price > 0) {
    if (totalSupply > 0 && reserves[0] > 0) {
      console.log(bal_lp, totalSupply, reserves, price)
      x = getSingleDataPoint_LP(
        bal_lp, totalSupply, reserves[reservesIndex], price)
       y = getSingleDataPoint_LP(u, totalSupply, reserves[reservesIndex], price)
      setDataPoint(Number(x) + Number(y))
    }
  } else {
    setDataPoint(0)
  }
}
// Calculate and Update Cult LP value -------------------------------------------------------------------
React.useEffect(()=>{
  setDP(
    bf_userInfo0, bal_slp_cult, price_cult, 
    totalSupply_slp_cult, reserves_cultftm, 1, setDataPoint_LP_cult)
}, [
  bal_slp_cult,
  totalSupply_slp_cult,
  reserves_cultftm,
  price_cult,
  bf_userInfo0
])

// Calculate and Update xCult LP value ------------------------------------------------------------------
React.useEffect(()=>{
  setDP(
    bf_userInfo1, bal_slp_xcult, price_xcult, 
    totalSupply_slp_xcult, reserves_xcultftm,1,setDataPoint_LP_xcult)
}, [
  bal_slp_xcult,
  totalSupply_slp_xcult,
  reserves_xcultftm,
  price_xcult,
  bf_userInfo1
])



// Calculate and Update Candle USDC LP value -------------------------------------------------------------
React.useEffect(()=>{
  setDP(
    bf_userInfo2, bal_lp_candleusdc, price_candle, 
    totalSupply_lp_candleusdc, reserves_candleusdc,1, setDataPoint_LP_candleusdc)
}, [
  bal_lp_candleusdc,
  totalSupply_lp_candleusdc,
  reserves_candleusdc,
  price_candle,
  bf_userInfo2
])

// Calculate and Update Candle USDC LP value -------------------------------------------------------------
React.useEffect(()=>{
  setDP(
    bf_userInfo3, bal_lp_cultxcult, price_cult, 
    totalSupply_lp_cultxcult, reserves_cultxcult,0, setDataPoint_LP_cultxcult)
}, [
  bal_lp_cultxcult,
  totalSupply_lp_cultxcult,
  reserves_cultxcult,
  price_cult,
  bf_userInfo3
])

const getDataPoint_LP = () => {
  return (datapoint_LP_cult + datapoint_LP_xcult + datapoint_LP_candle + datapoint_LP_candleusdc + datapoint_LP_cultxcult).toFixed(2)
}

React.useEffect(() => {
  let x
  if (datapoint_LP_cult + datapoint_LP_xcult + datapoint_LP_candle + datapoint_LP_candleusdc + datapoint_LP_cultxcult > 0) {
    x = getDataPoint_LP()
    setDataPoint_LP(Number(x))
    console.log("LP data", x)
  } else {
    setDataPoint_LP(0)
  }
}, [
  datapoint_LP_cult,
  datapoint_LP_xcult, 
  datapoint_LP_candleusdc,
  datapoint_LP_cultxcult
])

const [datapoint_NFT, setDataPoint_NFT] = React.useState(0)
const getDataPoint_NFT = () => {
  return (
              (balSkully * skullyFloor * price_ftm) +
              (balPopSkully * popskullyFloor* price_ftm) +
              (balGhostly * ghostlyFloor* price_ftm)
            ).toFixed(2)
}

React.useEffect(() => {
  let x
  if (skullyFloor > 0 && popskullyFloor > 0 && ghostlyFloor > 0) {
    if (balSkully > 0 || balPopSkully > 0 || balGhostly > 0) {
      if (price_ftm > 0) {
        x = getDataPoint_NFT()
        setDataPoint_NFT(Number(x))
        console.log("NFT data", x)
      }
    }
  }
}, [
  balSkully, 
  balPopSkully, 
  balGhostly, 
  skullyFloor, 
  popskullyFloor, 
  ghostlyFloor,
  price_ftm])

const [datapoint_ITEMS, setDataPoint_ITEMS] = React.useState(0)
const getDataPoint_ITEMS = () => {
  return (
              (
                Number(bal_item1) + Number(bal_item11) + Number(bal_item21) + 
                1000 * ( Number(bal_item2) + Number(bal_item12) ) + 
                3000 * ( Number(bal_item13)  + Number(bal_item22) ) + 
                5000 * Number(bal_item31)
              ) * price_candle
            ).toFixed(2)
}

React.useEffect(() => {
  let x
  if (bal_item1 + bal_item11 + bal_item21 + bal_item2 + bal_item12 + bal_item13 + bal_item22 + bal_item31 > 0) {
    if (price_candle > 0) {
      x = getDataPoint_ITEMS()
      setDataPoint_ITEMS(Number(x))
      console.log("ITEM data", x)
    }
  }
}, [
  bal_item1, 
  bal_item11, 
  bal_item21, 
  bal_item2, 
  bal_item12, 
  bal_item13,
  bal_item22,
  bal_item31,
  price_candle])

const [datapoint_CULT, setDataPoint_CULT] = React.useState(0)
const getDataPoint_CULT = () => {
  return getNetWorth(
          userInfo0, 
          balCult, 
          price_cult).toFixed(2)
}

React.useEffect(() => {
  let x
  if (userInfo0.hasOwnProperty('0')) {
    if (price_cult> 0) {
      x = getDataPoint_CULT()
      setDataPoint_CULT(Number(x))
      console.log("CULT data", x)
    }
  }
}, [
  userInfo0, 
  balCult,
  price_cult])

const [datapoint_xCULT, setDataPoint_xCULT] = React.useState(0)
const getDataPoint_xCULT = () => {
  return getNetWorth(
          userInfo1, 
          balXCult, 
          price_xcult).toFixed(2)
}
React.useEffect(() => {
  let x
  if (userInfo1.hasOwnProperty('0')) {
    if (price_xcult> 0) {
      x = getDataPoint_xCULT()
      setDataPoint_xCULT(Number(x))
      console.log("xCULT data", x)
    }
  }
}, [
  userInfo1, 
  balXCult,
  price_xcult])


const [datapoint_CANDLE, setDataPoint_CANDLE] = React.useState(0)
const getDataPoint_CANDLE = () => {
  return getNetWorth(
          userInfo2, 
          balCandle, 
          price_candle).toFixed(2)
}
React.useEffect(() => {
  let x
  if (userInfo2.hasOwnProperty('0')) {
    if (price_candle > 0) {
      x = getDataPoint_CANDLE()
      setDataPoint_CANDLE(Number(x))
      console.log("CANDLE data", x)
    }
  }
}, [
  userInfo2, 
  balCandle,
  price_candle])


const calcAndAddData = () => {
  if (chartData.length == 0) {
    addData("LP", datapoint_LP, chartData, setChartData)
    addData("NFTs", datapoint_NFT, chartData, setChartData)
    addData("Items", datapoint_ITEMS, chartData, setChartData)
    addData("Cult", datapoint_CULT, chartData, setChartData)
    addData("xCult", datapoint_xCULT, chartData, setChartData)
    addData("Candle", datapoint_CANDLE, chartData, setChartData)
  }
}

const claimCultByID_ref = React.createRef()
const claimxCultByPopSkullyID_ref = React.createRef()
const claimxCultByGhostlyID_ref = React.createRef()

const claimById = () => {
  claimByIdEstimate(
    cult,
    claimCultByID_ref,
    () => {
      
    }
    )
}

const claimByIdPopSkully = () => {
  claimByPopSkullyIdEstimate(
    xcult,
    claimxCultByPopSkullyID_ref,
    () => {
      
    }
    )
}

const claimByIdGhostly = () => {
  claimByGhostlyIdEstimate(
    xcult,
    claimxCultByGhostlyID_ref,
    () => {
      
    }
    )
}

const bf_ref__globalIncrease = React.createRef()
const bf_ref__globalDecrease = React.createRef()


const bf_ref__make0 = React.createRef()
const [bf_state__make0_b, set_bf_state__make0_b] = React.useState(0)
const bf_ref__break0 = React.createRef()
const [bf_state__break0_a, set_bf_state__break0_a] = React.useState(0)
const [bf_state__break0_b, set_bf_state__break0_b] = React.useState(0)
const bf_ref__stake0 = React.createRef()
const bf_ref__unstake0 = React.createRef()
const bf_ref__burn4Inc0 = React.createRef()
const bf_ref__burn4Dec0 = React.createRef()


const bf_ref__make1 = React.createRef()
const [bf_state__make1_b, set_bf_state__make1_b] = React.useState(0)
const bf_ref__break1 = React.createRef()
const [bf_state__break1_a, set_bf_state__break1_a] = React.useState(0)
const [bf_state__break1_b, set_bf_state__break1_b] = React.useState(0)

const bf_ref__stake1 = React.createRef()
const bf_ref__unstake1 = React.createRef()
const bf_ref__burn4Inc1 = React.createRef()
const bf_ref__burn4Dec1 = React.createRef()

const bf_ref__make2 = React.createRef()
const [bf_state__make2_b, set_bf_state__make2_b] = React.useState(0)
const bf_ref__break2 = React.createRef()
const [bf_state__break2_a, set_bf_state__break2_a] = React.useState(0)
const [bf_state__break2_b, set_bf_state__break2_b] = React.useState(0)

const bf_ref__stake2 = React.createRef()
const bf_ref__unstake2 = React.createRef()
const bf_ref__burn4Inc2 = React.createRef()
const bf_ref__burn4Dec2 = React.createRef()

const bf_ref__make3 = React.createRef()
const [bf_state__make3_b, set_bf_state__make3_b] = React.useState(0)
const bf_ref__break3 = React.createRef()
const [bf_state__break3_a, set_bf_state__break3_a] = React.useState(0)
const [bf_state__break3_b, set_bf_state__break3_b] = React.useState(0)

const bf_ref__stake3 = React.createRef()
const bf_ref__unstake3 = React.createRef()
const bf_ref__burn4Inc3 = React.createRef()
const bf_ref__burn4Dec3 = React.createRef()

const getBAmount = (ref, setter, reserves, _lpName) => {
  return () => {
    let rtr = new web3.eth.Contract(
      routers["Spooky"]["abi"],
      routers["Spooky"]["address"]
      )
    let x, y
    if (ref.hasOwnProperty('current')) {
      if (ref.current.hasOwnProperty('value')) {
        x = ref.current.value
       
        
          getReserves(
            _lpName,
            (r0, r1) => {
              y = web3.utils.toWei(x.toString(), 'ether')
              let _r0 
              let _r1 = web3.utils.toWei(r1.toString(), 'ether')
              if (_lpName == "wftm-usdc" || _lpName == "candle-usdc") {
                y = Math.round(Number(x)*1e6)
                _r0 = Math.round(r0*1e6)
                console.log(_lpName, _r0)
              } else {
                _r0 = web3.utils.toWei(r0.toString(), 'ether')
              }
              console.log(_lpName, x, y, _r0, _r1)
              rtr.methods.quote(y, 
                _r0, 
                _r1)
                .call({from: window.ethereum.selectedAddress})
                .then((res)=>{
                  setter(fromWei(res))
                })
            }
            )
        }  
      }
  }
}

const getBreakAmount = (ref, totalSupply, setterA, setterB, reserves, _lpName) => {
  return () => {
    let rtr = new web3.eth.Contract(
      routers["Spooky"]["abi"],
      routers["Spooky"]["address"]
      )
    let x, y
    if (ref.hasOwnProperty('current')) {
      if (ref.current.hasOwnProperty('value')) {
        x = ref.current.value
          let token = new web3.eth.Contract(tokens[_lpName]["abi"], tokens[_lpName]["address"])
          token.methods.totalSupply()
            .call({from: window.ethereum.selectedAddress})
            .then((res)=>{
              let z = fromWei(res)
              getReserves(
                _lpName,
                (r0, r1) => {
                  console.log("getBreakAmount ", r0, r1, x, z)
                  let amtA = Number(x)*r0/z
                  let amtB = Number(x)*r1/z
                  setterA(amtA)
                  setterB(amtB)
                }
                )
            })
          
        }  
      }
  }
}


const get_bfStake = (_userInfo) => {
  console.log("userInfo ",_userInfo)

}

/*React.useEffect(()=>{
  console.log("bf0 ", bf_poolInfo0)
}, [bf_poolInfo0])

React.useEffect(()=>{
  console.log("bfu0 ", bf_userInfo0)
}, [bf_userInfo0])
*/
React.useEffect(()=>{
  if (isBFOpen) {
    get_bfPoolLength()
    get_bfRate()
    get_bfAP()
    get_bfPerBlockChangeUnit()
    getFtmUsdc()
    getCandleUsdc()
    getCultFtm()
    getxCultFtm()
    getCultxCult()
    getTotalSupply(slp_cult,setTotalSupply_slp_cult)
    getTotalSupply(slp_xcult,setTotalSupply_slp_xcult)
    getTotalSupply(lp_candleusdc,setTotalSupply_lp_candleusdc)
    getTotalSupply(lp_cultxcult, setTotalSupply_lp_cultxcult)
    
  }
}, [isBFOpen])

const isLongOrEnough = (str, val) => {
  let isAllowed = false
  if (str.length > 27) {
    isAllowed = true
  } else {

    if (Number(fromWei(str)) >= Number(fromWei(val))) {
      isAllowed = true
    }
  }
  return isAllowed
}


const bf_afterPoolAction = (_pid, cb=()=>{}) => {
  return () => {
         setIsPending(false)
         setPendingMessage(PENDING_MESSAGE)
         let f = get_bfUserInfo(_pid)
         let g = get_bfPoolInfo(_pid)
         let x = get_bfPending(_pid)
         x()
         f()
         g()
         cb()
         getTokenBalance(candle, setBalCandle)
      }
    }
const bf_stake = (_pid, ref, cb=()=>{}) => {

  return () => {
    let tokenName = bf_pools[_pid]
    let depositToken = tokens[tokenName]["address"]

    let refWei = web3.utils.toWei(ref.current.value,'ether')
    let val = ref.current.value
    console.log("Checking Allowance ", depositToken)

    farm_burn.checkAllowance(
      depositToken,
      (allowance) => {
        console.log("isEnough", allowance, refWei)
        if (isLongOrEnough(allowance, refWei)) {
          setIsPending(true)
          setPendingMessage("Staking " + val + " " + tokenName)
          farm_burn.depositAmount(
            _pid,
            refWei,
            tokenName,
            bf_afterPoolAction(_pid, cb)
            )
        } else {
          setIsPending(true)
          setPendingMessage("Approving Burn Farms to spend " + val + " " + tokenName)
 
          farm_burn.approveAmount(
            refWei,
            depositToken,
            () => {
              
              setPendingMessage("Staking " + val + " " + tokenName)
              farm_burn.depositAmount(
                _pid,
                refWei,
                tokenName,
                bf_afterPoolAction(_pid, cb)
                )
            }
            )
        }
      }
      )
  }
}

const bf_unstake = (_pid, ref, cb=()=>{}) => {
  return () => {
    let tokenName = bf_pools[_pid]
    console.log(tokenName)
    let depositToken = tokens[tokenName]["address"]
    let refWei = web3.utils.toWei(ref.current.value,'ether')
   
  
    let val = ref.current.value
    
    setIsPending(true)
    setPendingMessage("Withdrawing " + val + " " + tokenName)
    farm_burn.withdrawAmount(
      _pid,
      refWei,
      tokenName,
      bf_afterPoolAction(_pid, cb)
      )
  }
}

const bf_harvest= (_pid, cb=()=>{}) => {

  return () => {
    let tokenName = bf_pools[_pid]
    let depositToken = tokens[tokenName]["address"]
    setIsPending(true)    
    setPendingMessage("Harvesting pending Candle for pool " + _pid.toString())
    farm_burn.depositAmount(
      _pid,
      0,
      tokenName,
      bf_afterPoolAction(_pid, ()=>{
        
      })
      )      
  }
}

const burn4AP = (_pid, ref, cb=()=>{}) => {

  return () => {
    let tokenName = "candle"
    let depositToken = tokens[tokenName]["address"]

    let refWei = web3.utils.toWei(ref.current.value,'ether')
    let val = ref.current.value
    console.log("Checking Allowance ", depositToken)

    farm_burn.checkAllowance(
      depositToken,
      (allowance) => {
        console.log("isEnough", allowance, refWei)
        if (isLongOrEnough(allowance, refWei)) {
          setIsPending(true)
          setPendingMessage("Burning " + val + " " + tokenName + " to increase Allocation Points of pool " + _pid.toString())
          farm_burn.burnCANDLEtoIncreaseAllocPoint(
            _pid,
            Number(val),
            () => {
              setIsPending(false)
              setPendingMessage(PENDING_MESSAGE)
              let f = get_bfPoolInfo(_pid)
              f()
              cb()
            }
            )
        } else {
          setIsPending(true)
          setPendingMessage("Approving Burn Farms to spend " + val + " " + tokenName)
 
          farm_burn.approveAmount(
            refWei,
            depositToken,
            () => {
              
              setPendingMessage("Burning " + val + " " + tokenName + " to increase Allocation Points of pool " + _pid.toString())
              farm_burn.burnCANDLEtoIncreaseAllocPoint(
                _pid,
                Number(val),
                () => {
                  setIsPending(false)
                  setPendingMessage(PENDING_MESSAGE)
                  let f = get_bfPoolInfo(_pid)
                  f()
                  cb()
                }
                )
            }
            )
        }
      }
      )
  }
}


const calcBurnEffect = (burnAmount, isIncrease = true) => {
  if (isIncrease) {
    return Number(fromWei(bf_emissionRate)) + burnAmount * Number(fromWei(bf_perBlockChangeUnit))
  } else {
    return Number(fromWei(bf_emissionRate)) - burnAmount * Number(fromWei(bf_perBlockChangeUnit)) * 110/100
  }
}

const [burnResult_inc, setBurnResult_inc] = React.useState(0)
const [burnResult_dec, setBurnResult_dec] = React.useState(0)
const setBurnResult = (ref, isIncrease = true) => {
  return () => {
    let x = calcBurnEffect(ref.current.value, isIncrease)
    if (isIncrease) {
      setBurnResult_inc(x)
    } else {
      setBurnResult_dec(x)
    }
  }
}

const burn4Globalcall = (val, isIncrease, tokenName, cb =()=>{}) => {
  setIsPending(true)
  if (isIncrease) {
    
    setPendingMessage(
      "Burning " + val + " " + tokenName + 
      " to increase global emission rate to " + burnResult_inc)
    farm_burn.increaseRate(
      Number(val),
      () => {
        setIsPending(false)
        setPendingMessage(PENDING_MESSAGE)
        get_bfRate()
        getTokenBalance(candle, setBalCandle)
        cb()
      }
      )
  } else {
    setPendingMessage(
      "Burning " + val + " " + tokenName + 
      " to decrease global emission rate to " + burnResult_dec)
    farm_burn.decreaseRate(
      Number(val),
      () => {
        setIsPending(false)
        setPendingMessage(PENDING_MESSAGE)
        get_bfRate()
        getTokenBalance(candle, setBalCandle)
        cb()
      }
      )
  }
}
const burn4Global = (ref, isIncrease = true, cb = ()=>{}) => {
  return () => {
    let tokenName = "candle"
    let depositToken = tokens[tokenName]["address"]

    let refWei = web3.utils.toWei(ref.current.value,'ether')
    let val = ref.current.value
    console.log("Checking Allowance ", depositToken)

    farm_burn.checkAllowance(
      depositToken,
      (allowance) => {
        console.log("isEnough", allowance, refWei)
        if (isLongOrEnough(allowance, refWei)) {
          burn4Globalcall(val, isIncrease, tokenName, cb)
        } else {
          setIsPending(true)
          setPendingMessage("Approving Burn Farms to spend " + val + " " + tokenName)
 
          farm_burn.approveAmount(
            refWei,
            depositToken,
            () => {
               burn4Globalcall(val, isIncrease, tokenName, cb)
              
            }
            )
        }
      }
      )
  }
}



const tkn = (nm) => {
  return tokens[nm]["address"]
}
const p4_cultxcult = (_out, isReverse = false, _in = "cult") => {
  let _alt = (_in == "cult") ? "xcult" : "cult"
  let x = [
    [
      tkn(_in),
      tkn("wftm")
    ],
    [
      tkn(_in),
      tkn(_alt)
    ]
  ]
  let y = [
    [_in, "wftm"],
    [_in, _alt]
  ]

  if (_out == _alt) {
    x[0][2] = tkn(_alt)
    y[0][2] = _alt
  }

  if (_out == "wftm" || _out == "usdc" || _out == "candle") {
    x[1][2] = tkn("wftm")
    y[1][2] = "wftm"
  }

  if (_out == "usdc" || _out == "candle") {
    x[0][2] = tkn("usdc")
    x[1][3] = tkn("usdc")
    y[0][2] = "usdc"
    y[1][3] = "usdc"
  }

  if (_out == "candle") {
    x[0][3] = tkn("candle")
    x[1][4] = tkn("candle")
    y[0][3] = "candle"
    y[1][4] = "candle"
  }

  if (isReverse) {
    x = [
      x[0].reverse(),
      x[1].reverse()
    ]
    y = [
      y[0].reverse(),
      y[1].reverse()
    ]
  }

  return [x, y]
}

const p4_candle = (_out, isReverse = false, _in = "candle") => {
  let x = [[tkn(_in),tkn("usdc")]]
  let y = [[_in,"usdc"]]
  if (_out == "wftm" || _out == "cult" || _out == "xcult") {
    x[0][2] = tkn("wftm")
    y[0][2] = "wftm"
  }

  if (_out == "cult" || _out == "xcult") {
    x[0][3] = tkn(_out)
    y[0][3] = _out
    x[1] = [x[0][0], x[0][1], x[0][2]]
    y[1] = [y[0][0], y[0][1], y[0][2]]
  }

  if (isReverse) {
    x[0].reverse()
    y[0].reverse()
  }

  if (_out == "cult") {
    x[1][3] = tkn("xcult")
    x[1][4] = tkn("cult")
    x[1][3] = "xcult"
    x[1][4] = "cult"
    if (isReverse) {
      x[1].reverse()
      y[1].reverse()
    }
  }

  if (_out == "xcult") {
    x[1][3] = tkn("cult")
    x[1][4] = tkn("xcult")
    y[1][3] = "cult"
    y[1][4] = "xcult"
    if (isReverse) {
      x[1].reverse()
      y[1].reverse()
    }
  }

  return [x, y]
}

const p4_soul = (_out, isReverse = false, _in = "candle") => {
  let x = [tkn(_in),tkn("soul"),tkn(_out)]
  let y = [_in,"soul",_out]
  

  if (isReverse) {
    x.reverse()
    y.reverse()
  }

  

  return [x, y]
}

const gao = (_amt, _path, cb = ()=>{}) => {
  rt.methods.getAmountsOut(_amt, _path)
    .call({from: window.ethereum.selectedAddress})
    .then((res)=>{
      cb(res)
    })
}

const gaosoul = (_amt, _path, cb = ()=>{}) => {
  rtsoul.methods.getAmountsOut(_amt, _path)
    .call({from: window.ethereum.selectedAddress})
    .then((res)=>{
      cb(res)
    })
}

// getAmountsOut = (_in, _path, swapper = () => {}, _nameIn = "", _nameOut = "")
// getAmountsOut(web3.utils.toWei(n.toString(), "ether"), _Path(_from,_to), swapToke4Toke, _from, _to)
const [swapRoutes, setSwapRoutes] = React.useState([])
const [swapRoutesNames, setSwapRoutesNames] = React.useState(["",""])
const [swapRoutesAmts0, setSwapRoutesAmts0] = React.useState([0,0])
const [swapRoutesAmts1, setSwapRoutesAmts1] = React.useState([0,0])
const [swapRoutesAmts2, setSwapRoutesAmts2] = React.useState([0,0])

const gaoX = (_amt, _in, _out, cb = ()=>{}) =>{
  let _isReverse = (!(_in == "candle" || _in == "cult" || _in == "xcult")) ? true : false
  let _useCandle = ((_in == "candle") || (_out == "candle" && _isReverse)) ? true : false
  let _X = (_useCandle) ? p4_candle : p4_cultxcult
  let _a = (_isReverse) ? _in : _out
  let _b = (_isReverse) ? _out : _in
  let amt
  if (_in == "usdc") {
    amt = _amt * 1e6
  } else {
    amt = web3.utils.toWei(_amt,'ether')
  }
  let [_path, _names] = _X(_a, _isReverse, _b)
  let [_pathSoul, _namesSoul] = p4_soul(_a, _isReverse, _b)
  if ( _path.length == 1) {
    _path[1] = ["0"]
    _names[1] = ["0"]
  }
  _path[2] = _pathSoul
  _names[2] = _namesSoul
  setSwapRoutes(_path)
  setSwapRoutesNames(_names)
  gao(
      amt, 
      _path[0], 
      (res) => {setSwapRoutesAmts0(res)})
  
  if (_path[1] != "0") {
    gao(
      amt, 
      _path[1], 
      (res) => {setSwapRoutesAmts1(res)})
    
  } else {
    setSwapRoutesAmts1([0,0])
  }
  gaosoul(
      amt, 
      _pathSoul, 
      (res) => {setSwapRoutesAmts2(res)})
  
}

const tref_inName = React.createRef()
const tref_outName = React.createRef()
const tref_inAmt = React.createRef()


  return (
    <div className={"App-wrapper connected--" + connected + " questReady--" + questReady}>

      <div className={"dialog dialog--" + isDialogOpen}>
        <div className="dialog__active">
          <div className={"dialog__speaker dialog__speaker--" + story[activeDialogIndex.toString()]["imgtype"]}>
            <img src={story[activeDialogIndex.toString()]["speaker"].default}/>
          </div>
          <div className={"dialog__text dialog__text--" + story[activeDialogIndex.toString()]["imgtype"]}>
          {
            story[activeDialogIndex.toString()]["text"].map(p=>(<p>{p}</p>))
          }
          </div>
        </div>
        <div className={"dialog__options dialog__options--" + checkedPreOptions}>
          
            {
              (story[activeDialogIndex.toString()].hasOwnProperty("password")) ?
              <div className="password">
                <input ref={passwordRef} defaultValue="password" />
                <button 
                  className="dialog__option" 
                  onClick={() => {
                    if (passwordRef.current.value == "Do you have the time?") {
                      setActiveDialogIndex(story[activeDialogIndex.toString()]["password"])
                    }
                  }}>Answer
                </button>
              </div> :
              
                story[activeDialogIndex.toString()]["options"].map(
                  (btn,index)=>(
                    <button 
                      key={index}
                      className={
                        "dialog__option dialog__option--" + 
                        activeDialogIndex.toString() + 
                        " dialog__option--" + index + " ifThree--" + ifThreeThenGo.toString()}
                      onClick={
                        goToDialog(
                          story[activeDialogIndex.toString()]["optiontarget"][index])}>
                      {btn}
                    </button>
                    ))
              
            
            }
          
        </div>

      </div>

      <div className={"pool pool-open--" + isPoolOpen_Cult}>
        <h2>You found a secret $CANDLE pool. Deposit $CULT to earn $CANDLE.</h2>
        <p>You must hold a briefcase to deposit and harvest.</p>
        <p>You must hold a key to withdraw.</p>
        <p>If you do not have either of these items, make sure you mint them at the Item Shop.</p>
        <div className="pool__manage">

          <div className="pool__token"><img src={tokens["cult"]["icon"]["default"]} /></div>
          <strong>{_g(userInfo0['0'])} $CULT</strong>
          <div className="block-buttons">
            <button className="pool__btn" 
              onClick={openInputViewAndDoStuff(0, "add")}>+</button>
            <button className="pool__btn" 
              onClick={openInputViewAndDoStuff(0, "remove")}>-</button>
          </div>
        </div>
        <div className="pool__harvest">
          <div className="pool__token"><img src={tokens["candle"]["icon"]["default"]} /></div>
          <strong>{formatTokenBalance(
            18, 
            pending0)} $CANDLE</strong>
          <div className="block-buttons">
            <button className="pool__btn" 
              onClick={returnHarvestFunction(0)}>harvest</button>
          </div>
        </div>
      </div>

      <div className={"pool pool-open--" + isPoolOpen_xCult}>
        <h2>You found a secret $CANDLE pool. Deposit $xCULT to earn $CANDLE.</h2>
        <p>You must hold an apple to deposit and harvest.</p>
        <p>You must hold a key to withdraw.</p>
        <p>If you do not have either of these items, make sure you mint them at the Item Shop.</p>
        <div className="pool__manage">

          <div className="pool__token"><img src={tokens["xcult"]["icon"]["default"]} /></div>
          <strong>{_g(userInfo1['0'])} $xCULT</strong>
          <div className="block-buttons">
            <button className="pool__btn" 
              onClick={openInputViewAndDoStuff(1, "add")}>+</button>
            <button className="pool__btn" 
              onClick={openInputViewAndDoStuff(1, "remove")}>-</button>
          </div>
        </div>
        <div className="pool__harvest">
          <div className="pool__token"><img src={tokens["candle"]["icon"]["default"]} /></div>
          <strong>{formatTokenBalance(
            18, 
            pending1)} $CANDLE</strong>
          <div className="block-buttons">
            <button className="pool__btn" 
              onClick={returnHarvestFunction(1)}>harvest</button>
          </div>
        </div>
      </div>

      <div className={"pool pool-open--" + isPoolOpen_Candle}>
        <h2>You found a secret $CANDLE pool. Deposit $CANDLE to earn $CANDLE.</h2>
        <p>You must hold an apple or a briefcase to deposit and harvest.</p>
        <p>You must hold a key to withdraw.</p>
        <p>If you do not have either of these items, make sure you mint them at the Item Shop.</p>
        <div className="pool__manage">

          <div className="pool__token">
            <img src={tokens["candle"]["icon"]["default"]} />
            
          </div>
          <strong>{_g(userInfo2['0'])} $CANDLE</strong>
          <div className="block-buttons">
            <button className="pool__btn" 
              onClick={openInputViewAndDoStuff(2, "add")}>+</button>
            <button className="pool__btn" 
              onClick={openInputViewAndDoStuff(2, "remove")}>-</button>
          </div>
        </div>
        <div className="pool__harvest">
          <div className="pool__token"><img src={tokens["candle"]["icon"]["default"]} /></div>
          <strong>{formatTokenBalance(
            18, 
            pending2)} $CANDLE</strong>
          <div className="block-buttons">
            <button className="pool__btn" 
              onClick={returnHarvestFunction(2)}>harvest</button>
          </div>
        </div>
      </div>

        <button 
          disabled={isDisabled} 
          onClick={connectMM} 
          className="mmbtn">
          {mmBtnText}
        </button>

        <div className={"store store-open--" + isStoreOpen}>
          <div className={"store__img"}><img src={bg.default} /></div>
          <div className="store__options">
            <button className="store__option" onClick={mint1155(1)}>
              <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/apple.png" />
              <span>Apple</span>
              <span>1 $CANDLE</span>
              <span className="smaller">{minted_item1} / 1,000,000 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(2)}>
              <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/apple_bite.png" />
              <span>Apple (..bitten)</span>
              <span>1,000 $CANDLE</span>
              <span className="smaller">{minted_item2} / 100 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(11)}>
              <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/briefcase_plain.png" />
              <span>Briefcase</span>
              <span>1 $CANDLE</span>
              <span className="smaller">{minted_item11} / 1,000,000 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(12)}>
              <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/briefcase.png" />
              <span>Cult Briefcase</span>
              <span>1,000 $CANDLE</span>
              <span className="smaller">{minted_item12} / 100 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(13)}>
              <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/briefcase_bone.png" />
              <span>Bone Handle Briefcase</span>
              <span>3,000 $CANDLE</span>
              <span className="smaller">{minted_item13} / 100 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(21)}>
              <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/key.png" />
              <span>Skeleton Key</span>
              <span>1 $CANDLE</span>
              <span className="smaller">{minted_item21} / 1,000,000 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(22)}>
              <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/key_bone.png" />
              <span>Bone Key</span>
              <span>3,000 $CANDLE</span>
              <span className="smaller">{minted_item22} / 100 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(31)}>
              <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/matchbook.png" />
              <span>Matchbook</span>
              <span>5,000 $CANDLE</span>
              <span className="smaller">{minted_item31} / 42 minted</span>
            </button>
          </div>
        </div>

      <div className={
        "inventory inventory--1155 inventory-open--" + is1155InventoryOpen}>
        <div className="inv-item inv-item--nft">
          <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/apple.png" />
          <div className="inv-bal">{bal_item1} <span>Apple</span></div>
        </div>
        <div className="inv-item inv-item--nft">
          <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/apple_bite.png" />
          <div className="inv-bal">{bal_item2} <span>Bitten Apple</span></div>
        </div>
        <div className="inv-item inv-item--nft">
          <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/briefcase_plain.png" />
          <div className="inv-bal">{bal_item11} <span>Briefcase</span></div>
        </div>
        <div className="inv-item inv-item--nft">
          <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/briefcase.png" />
          <div className="inv-bal">{bal_item12} <span>Cult Briefcase</span></div>
        </div>
        <div className="inv-item inv-item--nft">
          <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/briefcase_bone.png" />
          <div className="inv-bal">{bal_item13} <span>Bone Handle Briefcase</span></div>
        </div>
        <div className="inv-item inv-item--nft">
          <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/key.png" />
          <div className="inv-bal">{bal_item21} <span>Skeleton Key</span></div>
        </div>
        <div className="inv-item inv-item--nft">
          <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/key_bone.png" />
          <div className="inv-bal">{bal_item22} <span>Bone Key</span></div>
        </div>
        <div className="inv-item inv-item--nft">
          <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/matchbook.png" />
          <div className="inv-bal">{bal_item31} <span>Matchbook</span></div>
        </div>
     </div>
     <button
        className={
          "btn-inventory--1155  btn-inventory-open--" + is1155InventoryOpen}
        onClick={toggle1155Inventory}>
        Items
      </button>

      <div className={"inventory inventory-open--" + isInventoryOpen}>
        <div className="inv-item inv-item--nft">
          <img className="nft-thumb" src={nfts["skullys"]["img"].default} />
          <div className="inv-bal">{balSkully} <span>Skullys</span></div>
        </div>
        <div className="inv-item inv-item--nft">
          <img className="nft-thumb" src={nfts["popskullys"]["img"].default} />
          <div className="inv-bal">{balPopSkully} <span>Pop Skullys</span></div>
        </div>
        <div className="inv-item inv-item--nft">
          <img className="nft-thumb" src={nfts["ghostlys"]["img"].default} />
          <div className="inv-bal">{balGhostly} <span>Ghostlys</span></div>
        </div>

        
        

        <div className="inv-item inv-item--token">
          <img className="token-icon" src={tokens["cult"]["icon"].default} />
          <div className="inv-bal">{fs(balCult)} <span>CULT</span></div>
        </div>
        <div className="inv-item inv-item--token">
          <img className="token-icon" src={tokens["xcult"]["icon"].default} />
          <div className="inv-bal">{fs(balXCult)} <span>xCULT</span></div>
        </div>
        <div className="inv-item inv-item--token">
          <img className="token-icon" src={tokens["candle"]["icon"].default} />
          <div className="inv-bal">{fs(balCandle)} <span>CANDLE</span></div>
        </div>
      </div>

      <button
        className={"btn-inventory btn-inventory-open--" + isInventoryOpen}
        onClick={toggleInventory}>
        Tokens
      </button>


     {
      <InputView 
        toggle={theInputTOGGLE}
        intent={theInputINTENT}
        theInputRef={theInputRef}
        setInputRefToMAX={setInputRefToMAX}
        poolBalance={
          formatTokenBalance(
            fromTokenNameToDecimals(theTargetNAME), 
            eval("userInfo" + theTargetPOOL + "['0']"))}
        balance={formatTokenBalance(fromTokenNameToDecimals(theTargetNAME), balanceOfTarget)}
        allowance={formatTokenBalance(fromTokenNameToDecimals(theTargetNAME), allowanceOfTarget)}
        toggleInput={toggleInput}
        onClick_Approve={onClick_Approve}
        triggerDeposit={triggerDeposit}
        triggerWithdraw={triggerWithdraw} />
      }

      <div className={"pending pending--" + isPending}>{pendingMessage}</div>
      <button 
        className={"start-over start-over--begin start-over--" + isDialogOpen}
        onClick={() => {
          
          setIsDialogOpen(true)
          setIsPoolOpen_Cult(false)
          setIsPoolOpen_xCult(false)
          setIsPoolOpen_Candle(false)
          setIsStoreOpen(false)
        }}>
        Quest
      </button>

      <button
        className={"start-over"}
        onClick={toggleStore}>
        Item Shop
      </button>

      <div className={"modal tools tools--" + isToolsOpen}>
        <div className="tools__box">
          <div className="tools__box-sub tools__box-sub--swapper">
            <div className="swapper-box">
              <h3>Swapper</h3>
              <p className="swapper-p">Type the input token name and amount, and the desired output token name.</p>
              <p className="swapper-p sp">cult - xcult - candle - usdc - wftm</p>
              <div className="swapper-inputs">
                <input ref={tref_inName} defaultValue="cult" className="swapper-name" />
                <input ref={tref_inAmt} defaultValue="0.1" className="swapper-amt"/>
                <input ref={tref_outName} defaultValue="xcult" className="swapper-name swapper-name--out" />
              </div>
              <button onClick={
                () => {
                  gaoX(tref_inAmt.current.value, tref_inName.current.value, tref_outName.current.value)
                }
              }>Check Routes</button>
              <div className="routes">
                
                  <div 
                    className="route" 
                    onClick={
                      ()=>{
                        swapT4Tp(
                          tref_inAmt.current.value, 
                          swapRoutes[0],
                          swapRoutesNames[0][0],
                          swapRoutesNames[0][swapRoutesNames[0].length - 1]
                          )}}>
                    {
                      swapRoutesAmts0.map((a, i) => (
                        <div 
                          key={"route-el"+i} 
                          className={
                            "route-el " +
                            "route-el--" + i +
                            " route-el--last-" + (i < swapRoutesAmts0.length - 1)
                          }>
                          {Number(fromWei(a,swapRoutesNames[0][i])).toFixed(3)} 
                          <strong> {swapRoutesNames[0][i]}</strong>
                          {
                            (i < swapRoutesAmts0.length - 1) ? 
                            <span> >></span> : ""
                          }
                        </div>
                        ))
                    }
                    <span className="abs">spooky.fi</span>
                  </div>
                  <div 
                    className="route"
                    onClick={
                      ()=>{
                        if (swapRoutesAmts1[0] !== 0) {
                          swapT4Tp(
                            tref_inAmt.current.value, 
                            swapRoutes[1],
                            swapRoutesNames[1][0],
                            swapRoutesNames[1][swapRoutesNames[1].length - 1]
                            )
                          }
                        }
                      }>
                    {
                      (swapRoutesAmts1[0] !== 0) ?
                      swapRoutesAmts1.map((a, i) => (
                        <div key={"route-el"+i} className={
                            "route-el " +
                            "route-el--" + i +
                            " route-el--last-" + (i < swapRoutesAmts1.length - 1)
                          }>
                          {Number(fromWei(a,swapRoutesNames[1][i])).toFixed(3)} 
                          <strong> {swapRoutesNames[1][i]}</strong>
                          {
                            (i < swapRoutesAmts1.length - 1) ? 
                            <span> >></span> : ""
                          }

                        </div>
                        )) : ""
                    }
                    <span className="abs">spooky.fi</span>
                  </div>
                  <div 
                    className="route"
                    onClick={
                      ()=>{
                        if (swapRoutesAmts2[0] !== 0) {

                          swapT4Tpsoul(
                            tref_inAmt.current.value, 
                            swapRoutes[2],
                            swapRoutesNames[2][0],
                            swapRoutesNames[2][swapRoutesNames[2].length - 1]
                            )
                          }
                        }
                      }>
                    {
                      (swapRoutesAmts2[0] !== 0) ?
                      swapRoutesAmts2.map((a, i) => (
                        <div 
                          key={"route-el"+i} 
                          className={
                            "route-el " +
                            "route-el--" + i +
                            " route-el--last-" + (i < swapRoutesAmts2.length - 1)
                          }>
                          {Number(fromWei(a,swapRoutesNames[2][i])).toFixed(3)} 
                          <strong> {swapRoutesNames[2][i]}</strong>
                          {
                            (i < swapRoutesAmts2.length - 1) ? 
                            <span> >></span> : ""
                          }

                        </div>
                        )) : ""
                    }
                    <span className="abs">soul.sh</span>
                  </div>
              </div>
              <p className="swapper-p">click a route to execute the swap.</p>
            </div>
          </div>
        </div>
        <div className="tools__box">
          <h3>$CULT Tools</h3>
          <div className="tools__box-sub toolbox-container">
            <div className="toolbox toolbox--left">
              <div className="toolbox__img">
                <img src={tokens["cult"]["icon"].default} />
              </div>
              <div className="toolbox__price">
                <div className="token-name">CULT</div>
                <div className="price">${Number(price_cult).toFixed(2)}</div>
              </div>
            </div>
            <div className="toolbox toolbox--right">
              <InfoRow 
                rowname="Claimed"
                amt={totalSupply_cult}
                price={price_cult * totalSupply_cult}/>
              <InfoRow 
                rowname="Unclaimed"
                amt={1000 - totalSupply_cult}
                price={price_cult * (1000 - totalSupply_cult)} />
              <InfoRow 
                rowname="Liquidity"
                amt={reserves_cultsoul[0] + reserves_cultftm[1] + reserves_cultxcult[0]}
                price={price_cult * (reserves_cultsoul[0] + reserves_cultftm[1] + reserves_cultxcult[0])} />
           
              <ClaimRow 
                claimById={claimById}
                claimByID_Ref={claimCultByID_ref}
                defaultValue="Skully ID" />
              <button className="load-data" onClick={()=>{getCultSoulUsdc()}}>
                Load Data
              </button>
            </div>
            <BoxRow index="0">
              <BoxPart parttype="left">

                <button onClick={()=>{getHasClaimed_Skully(hasClaimed_SkullyRef)}}>
                  Check Claim by ID
                </button>
              </BoxPart>
              <BoxPart parttype="right">
                <input ref={hasClaimed_SkullyRef} defaultValue="token id" />
              </BoxPart>
            </BoxRow>
            <BoxRow index="1">
              <BoxPart parttype="">
                <p>{hasClaimedMessage}</p>
              </BoxPart>
            </BoxRow>
            <BoxRow index="2">
              <BoxPart parttype="left">
                <button onClick={() => {getPaintswapData("skullys", setSkullyFloorID, setSkullyFloor)}}>
                  Check Skully Floor
                </button>
              </BoxPart>
              <BoxPart parttype="border-right">
                <p><a 
                  href={pnt + nfts["skullys"]["address"] + "/" + skullyFloorID.toString()} 
                  target="_blank">{skullyFloor} FTM for Skully #{skullyFloorID}</a></p>
              </BoxPart>
            </BoxRow>
            <BoxRow index="3">
              <BoxPart parttype="">
                <button onClick={()=>{claimAllEstimate(cult, ()=>{alert("try claiming by ID")})}}>
                  Claim All $CULT
                </button>
              </BoxPart>
              
            </BoxRow>
            
            <BoxRow index="4">
              <BoxPart parttype="">
                <button onClick={claimByIdLoop}>
                Claim $CULT for Skully #{skully0}
              </button>
              </BoxPart>
               
            </BoxRow>
            <BoxRow index="5">
              <BoxPart parttype="smallp">
                  <p>If you already claimed for the displayed tokenID, click the button and it will get your next tokenID.</p>
              </BoxPart>
               
            </BoxRow>
          </div>
          
          <div className="tools__box-sub tools__box-sub--swapper">
            <h4>Swap for $CULT</h4>  
            <button onClick={()=>{swapNftm(1,"cult")}}>
              Swap 1 FTM for $CULT
            </button>
            <button onClick={()=>{swapNftm(5,"cult")}}>
              Swap 5 FTM for $CULT
            </button>
            <button onClick={()=>{swapT4T(1,"xcult","cult")}}>
              Swap 1 $xCULT for $CULT
            </button>
            <button onClick={()=>{swapT4T(100,"candle","cult")}}>
              Swap 100 $CANDLE for $CULT
            </button>
          </div>    
        </div>
        <div className="tools__box">
          <h3>$xCULT Tools</h3>
          <div className="tools__box-sub toolbox-container--taller">
            
            <div className="toolbox toolbox--left">
              <div className="toolbox__img">
                <img src={tokens["xcult"]["icon"].default} />
              </div>
              <div className="toolbox__price">
                <div className="token-name">xCULT</div>
                <div className="price">${Number(price_xcult).toFixed(2)}</div>
              </div>
            </div>
            <div className="toolbox toolbox--right">
              <InfoRow 
                rowname="Claimed"
                amt={totalSupply_xcult}
                price={price_xcult * totalSupply_xcult}/>
              <InfoRow 
                rowname="Unclaimed"
                amt={18887 - totalSupply_xcult}
                price={price_xcult * (18887 - totalSupply_xcult)} />
              <InfoRow 
                rowname="Liquidity"
                amt={reserves_xcultsoul[0] + reserves_xcultftm[1] + reserves_cultxcult[1]}
                price={price_xcult *(reserves_xcultsoul[0] + reserves_xcultftm[1] + reserves_cultxcult[1])} />
           
              <ClaimRow 
                claimById={claimByIdPopSkully}
                claimByID_Ref={claimxCultByPopSkullyID_ref}
                defaultValue="PopSkully ID" />
              <ClaimRow 
                claimById={claimByIdGhostly}
                claimByID_Ref={claimxCultByGhostlyID_ref}
                defaultValue="Ghostly ID" />
        
              <button className="load-data" onClick={()=>{getxCultSoulUsdc()}}>
                Load Data
              </button>
            </div>
            <BoxRow index="0">
              <div className="box-part box-part--left">

                <button onClick={()=>{getHasClaimed_PopSkully(hasClaimed_PopSkullyRef)}}>
                  Check by Pop Skully ID
                </button>
              </div>
              <div className="box-part box-part--right">
                <input ref={hasClaimed_PopSkullyRef} defaultValue="token id" />
              </div>
            </BoxRow>
            <BoxRow index="1">
              <BoxPart parttype="">
                <p>{hasClaimedMessage_Pop}</p>
              </BoxPart>
            </BoxRow>
            <BoxRow index="2">
              <BoxPart parttype="left">
                <button onClick={() => {getPaintswapData("popskullys", setPopSkullyFloorID, setPopSkullyFloor)}}>
                  Check Pop Skully Floor
                </button>
              </BoxPart>
              <BoxPart parttype="border-right">
                <p><a 
                  href={pnt + nfts["popskullys"]["address"] + "/" + popskullyFloorID.toString()} 
                  target="_blank">{popskullyFloor} FTM for Pop Skully #{popskullyFloorID}</a></p>
              </BoxPart>
            </BoxRow>
            <BoxRow index="3">
              <BoxPart parttype="left">

                <button onClick={()=>{getHasClaimed_Ghostly(hasClaimed_GhostlyRef)}}>
                  Check by Ghostly ID
                </button>
              </BoxPart>
              <BoxPart parttype="right">
                <input ref={hasClaimed_GhostlyRef} defaultValue="token id" />
              </BoxPart>
            </BoxRow>
            <BoxRow index="4">
              <BoxPart type="">
                <p>{hasClaimedMessage_Ghostly}</p>
              </BoxPart>
            </BoxRow>
            <BoxRow index="5">
              <BoxPart parttype="left">
                <button onClick={() => {getPaintswapData("ghostlys", setGhostlyFloorID, setGhostlyFloor)}}>
                  Check Ghostly Floor
                </button>
              </BoxPart>
              <BoxPart parttype="border-right">
                <p><a 
                  href={pnt + nfts["ghostlys"]["address"] + "/" + ghostlyFloorID.toString()} 
                  target="_blank">{ghostlyFloor} FTM for Ghostly #{ghostlyFloorID}</a></p>
              </BoxPart>
            </BoxRow>
            <BoxRow index="6">
              <BoxPart parttype="">
                <button onClick={()=>{claimAllEstimate(xcult, ()=>{alert("try claiming by ID")})}}>
                   Claim All $xCULT
                </button>
              </BoxPart>
              
            </BoxRow>
            
            <BoxRow index="7">
              <BoxPart parttype="">
                <button onClick={claimByPopSkullyIdLoop}>
              Claim $xCULT for Pop Skully #{popskully0}
            </button>
              </BoxPart>
               
            </BoxRow>
            <BoxRow index="8">
              <BoxPart parttype="">
                <button onClick={claimByGhostlyIdLoop}>
              Claim $xCULT for Ghostly #{ghostly0}
            </button>
              </BoxPart>
               
            </BoxRow>
            <BoxRow index="9">
              <BoxPart parttype="">
                <button onClick={mintPopSkully}>
              Mint a Pop Skully for 50 FTM
            </button>
              </BoxPart>
               
            </BoxRow>
            <BoxRow index="10">
              <BoxPart parttype="">
                <p>
                  A Pop Skully costs ${Number(50*price_ftm).toFixed(2)} and can claim ${Number(3*price_xcult)} xCULT for a Net Loss of ${Number(50*price_ftm - 3*price_xcult).toFixed(2)}
                </p>
              </BoxPart>
               
            </BoxRow>
          </div>
          <div className="tools__box-sub tools__box-sub--swapper">
           
            <h4>Swap for $xCULT</h4>
            <button onClick={()=>{swapNftm(1,"xcult")}}>
              Swap 1 FTM for $xCULT
            </button>
            <button onClick={()=>{swapNftm(5,"xcult")}}>
              Swap 5 FTM for $xCULT
            </button>
            <button onClick={()=>{swapT4T(0.01,"cult", "xcult")}}>
              Swap 0.01 $CULT for $xCULT
            </button>
            <button onClick={()=>{swapT4T(0.1,"cult", "xcult")}}>
              Swap 0.1 $CULT for $xCULT
            </button>
            <button onClick={()=>{swapT4T(100,"candle", "xcult")}}>
              Swap 100 $CANDLE for $xCULT
            </button>
            
          </div>
        </div>
        <div className="tools__box">
          <h3>$CANDLE Tools</h3>
          <div className="tools__box-sub toolbox-container--shorter">
            <div className="toolbox toolbox--left">
              <div className="toolbox__img">
                <img src={tokens["candle"]["icon"].default} />
              </div>
              <div className="toolbox__price">
                <div className="token-name">CANDLE</div>
                <div className="price">${Number(price_candle).toFixed(2)}</div>
              </div>
            </div>
            <div className="toolbox toolbox--right">
              <InfoRow 
                rowname="Total"
                amt={totalSupply_candle}
                price={price_candle * totalSupply_candle}/>
              <InfoRow 
                rowname="Burned"
                amt={bal_dead_candle}
                price={price_candle * bal_dead_candle}/>
              <InfoRow 
                rowname="Airdrop"
                amt={bal_mkt_candle}
                price={price_candle * bal_mkt_candle}/>
              <InfoRow 
                rowname="Circulating"
                amt={(totalSupply_candle - bal_dead_candle - bal_mkt_candle)}
                price={price_candle * (totalSupply_candle - bal_dead_candle - bal_mkt_candle)}/>
              <InfoRow 
                rowname="Liquidity"
                amt={reserves_candlesoul[0] + reserves_candleusdc[1]}
                price={price_candle * (reserves_candlesoul[0] + reserves_candleusdc[1])}/>
              <InfoRow 
                rowname="Gated Pools"
                amt={bal_gate_candle}
                price={price_candle * bal_gate_candle}/>
              <InfoRow 
                rowname="(de)genesis"
                amt={bal_dgn_candle}
                price={price_candle * bal_dgn_candle}/>

              <button className="load-data" onClick={()=>{getCandleSoulUsdc()}}>
                Load Data
              </button>
            </div>
            </div>
          <div className="tools__box-sub">
            
          
            
            
            
            
            
            <div className="chart">
              <PiCh data={
                [
                  {
                    name: "Liquidity", 
                    value:reserves_candlesoul[0] + reserves_candleusdc[1], 
                    labeltype: 'after',
                    labelafter: 'CANDLE'
                  },
                  {
                    name: "Gate Pools", 
                    value:bal_gate_candle, 
                    labeltype: 'after',
                    labelafter: 'CANDLE'
                  },
                  {
                    name: "(de)genesis", 
                    value:bal_dgn_candle, 
                    labeltype: 'after',
                    labelafter: 'CANDLE'
                  },
                  {
                    name: "Wallets", 
                    value:(totalSupply_candle - bal_dead_candle - bal_mkt_candle - bal_gate_candle - bal_dgn_candle - reserves_candlesoul[0]), 
                    labeltype: 'after',
                    labelafter: 'CANDLE'
                  }
                ]
              } />
            </div>

          </div>
          <div className="tools__box-sub tools__box-sub--swapper">
           
            <h4>Swap for $CANDLE</h4>

            <button onClick={()=>{swapNftm(1,"candle")}}>
              Swap 1 FTM for $CANDLE
            </button>
            <button onClick={()=>{swapNftm(5,"candle")}}>
              Swap 5 FTM for $CANDLE
            </button>
            <button onClick={()=>{swapT4T(0.01,"cult","candle")}}>
              Swap 0.01 Cult for $CANDLE
            </button>
            <button onClick={()=>{swapT4T(1,"xcult","candle")}}>
              Swap 1 xCult for $CANDLE
            </button>
          
          </div>
        </div>
      </div>

      <button
        className={"start-over start-over--tools"}
        onClick={toggleTools}>
        Tools
      </button>

      <button
        className={"start-over start-over--portfolio"}
        onClick={toggleFoli}>
        Portfolio
      </button>

      <button
        className={"start-over start-over--bf"}
        onClick={toggleBF}>
        Burn Farms
      </button>
      

      <div className={"modal foli foli--" + isFoliOpen}>
        <h3>Portfolio</h3>
        <h4>${
          (datapoint_ITEMS + datapoint_CULT + datapoint_xCULT + datapoint_CANDLE + datapoint_NFT + datapoint_LP).toFixed(2).toLocaleString('en-US')
        } Net Worth</h4>

        <div className="foli__chart"><PiCh data={chartData} /></div>
        <div className="refresh">
            <button onClick={() => {
              if (chartData.length> 0) {
                setChartData([])
              } else {
                readyFoli()
                calcAndAddData()
              }
              
            }}>Refresh Data</button>
          </div>
        <HeaderRowP />
        <ValEl 
          rowname="CULT" 
          denominator={totalSupply_cult}
          balState={balCult} 
          userInfoState={userInfo0} 
          priceState={price_cult} 
          tokenName="CULT" 
          getNetWorth={getNetWorth} />
        <ValEl 
          rowname="xCULT" 
          denominator={totalSupply_xcult}
          balState={balXCult} 
          userInfoState={userInfo1} 
          priceState={price_xcult} 
          tokenName="xCULT"
          getNetWorth={getNetWorth} />
        <ValEl 
          rowname="CANDLE" 
          denominator={(totalSupply_candle - bal_dead_candle - bal_mkt_candle)} 
          balState={balCandle} 
          userInfoState={userInfo2} 
          priceState={price_candle} 
          tokenName="CANDLE" 
          getNetWorth={getNetWorth} />
        <div className="foli-lps">
          
          <InfoRow 
            rowname="Liquidity"
            amt={0}
            price={datapoint_LP} />
          <InfoRow 
            rowname="Cult-Ftm Spooky LP"
            rowtype="sub"
            amt={Number(fromWei(bal_slp_cult))}
            price={datapoint_LP_cult} />
          <InfoRow 
            rowname="xCult-Ftm Spooky LP"
            rowtype="sub"
            amt={Number(fromWei(bal_slp_xcult))}
            price={datapoint_LP_xcult} />
          
          <InfoRow 
            rowname="Candle-Usdc Spooky LP"
            rowtype="sub"
            amt={Number(fromWei(bal_lp_candleusdc))}
            price={datapoint_LP_candleusdc} />
          <InfoRow 
            rowname="Cult-xCult Spooky LP"
            rowtype="sub"
            amt={Number(fromWei(bal_lp_cultxcult))}
            price={datapoint_LP_cultxcult} />
          
          
        </div>
        <div className="foli-nfts">
          <InfoRow 
            rowname="ERC 721"
            amt={Number(balSkully) + Number(balPopSkully) + Number(balGhostly)}
            price={datapoint_NFT} />
          <InfoRow 
            rowname="Skullys"
            rowtype="sub"
            amt={Number(balSkully)}
            price={balSkully * skullyFloor * price_ftm} />
          <InfoRow 
            rowname="Pop Skullys"
            amt={Number(balPopSkully)}
            rowtype="sub"
            price={balPopSkully * popskullyFloor* price_ftm} />
          <InfoRow 
            rowname="Ghostlys"
            amt={Number(balGhostly)}
            rowtype="sub"
            price={balGhostly * ghostlyFloor* price_ftm} />
          <p className="footnote">calculated using the floor price on paintswap</p>
          <InfoRow 
            rowname="ERC 1155"
            amt={
              Number(bal_item1) + Number(bal_item2) + 
              Number(bal_item11) + Number(bal_item12) + Number(bal_item13) + 
              Number(bal_item21) + Number(bal_item22) + Number(bal_item31) 
            }
            price={datapoint_ITEMS} />
          <InfoRow_1155
            rowname="Apple"
            bal={bal_item1}
            price={price_candle}
            cost={1} />
          <InfoRow_1155
            rowname="Bitten Apple"
            bal={bal_item2}
            price={price_candle}
            cost={1000} />
          <InfoRow_1155
            rowname="Briefcase"
            bal={bal_item11}
            price={price_candle}
            cost={1} />
          <InfoRow_1155
            rowname="Members Briefcase"
            bal={bal_item12}
            price={price_candle}
            cost={1000} />
          <InfoRow_1155
            rowname="Bone Handle Briefcase"
            bal={bal_item13}
            price={price_candle}
            cost={3000} />
          <InfoRow_1155
            rowname="Skeleton Key"
            bal={bal_item21}
            price={price_candle}
            cost={1} />
          <InfoRow_1155
            rowname="Bone Key"
            bal={bal_item22}
            price={price_candle}
            cost={3000} />
          <InfoRow_1155
            rowname="Matchbook"
            bal={bal_item31}
            price={price_candle}
            cost={5000} />
          <p className="footnote">calculated using the candle burned to mint the items and the price of candle</p>
        </div>
      </div>

      <div className={
        "modal burn-farms burn-farms--" + isBFOpen + 
        " burn-farms-deposit--" + isBFDeposit + 
        " burn-farms-withdraw--" + isBFWithdraw +
        " burn-farms-burn--" + isBFBurn +
        " burn-farms-info--" + isBFInfo
      }>
        <h3>Burn Farms</h3>
        <p>Emitting {Number(fromWei(bf_emissionRate)).toFixed(5)} $CANDLE per block.</p>
        <p>If Fantom block times are 1-2s, the daily emissions are {
          (Number(fromWei(bf_emissionRate))*24*60*30).toFixed(0)
        }-{
          (Number(fromWei(bf_emissionRate))*24*60*60).toFixed(0)
        } $CANDLE per day</p>
        <div className="burn-farms__nav">
          <button 
            className={"bf-btn bf-btn--" + isBFInfo} 
            onClick={()=>{changeActiveBF(3)}}>Info</button>
          <button 
            className={"bf-btn bf-btn--" + isBFDeposit} 
            onClick={()=>{changeActiveBF(0)}}>
            <img src={idep.default} />
            <span>Deposit</span></button>
          <button 
            className={"bf-btn bf-btn--" + isBFWithdraw} 
            onClick={()=>{changeActiveBF(1)}}>
            <img src={iw.default} />
            <span>Withdraw</span></button>
          <button 
            className={"bf-btn bf-btn--" + isBFBurn} 
            onClick={()=>{changeActiveBF(2)}}><img src={ic.default} />
            <span>Burn</span></button>
          
        </div>
        <p className="burn-farms__nav p-sep bf-restrict-burn">Burn CANDLE to Increase the Global Emission Rate</p>
        <div className="burn-farms__nav bf-restrict-burn global-row">
          <input ref={bf_ref__globalIncrease} defaultValue="0"/>
          <button 
            className={"bf-btn"} 
            onClick={setBurnResult(bf_ref__globalIncrease)}>Check New Rate</button>
          <div className="burn-result">{burnResult_inc.toFixed(5)}</div>
          <button 
            className={"bf-btn"} 
            onClick={burn4Global(
              bf_ref__globalIncrease, 
              true,
              () => {

              }
              )}>Increase Global Emission Rate</button>
        </div>
        <p className="burn-farms__nav p-sep bf-restrict-burn">Burn CANDLE to Decrease the Global Emission Rate</p>
        <div className="burn-farms__nav burn-farms__nav--last bf-restrict-burn global-row">
          <input ref={bf_ref__globalDecrease} defaultValue="0"/>
          <button 
            className={"bf-btn"} 
            onClick={setBurnResult(bf_ref__globalDecrease, false)}>Check New Rate</button>
          <div className="burn-result">{burnResult_dec.toFixed(5)}</div>
          <button 
            className={"bf-btn"} 
            onClick={burn4Global(
              bf_ref__globalDecrease, 
              false,
              () => {
                
              }
              )}>Decrease Global Emission Rate</button>
        </div>
        
        <div className="burn-farms__header bf-restrict-deposit">
          <div className="col col--0">
            Pair
          </div>
          <div className="col col--1">
            Your LP
          </div>
          <div className="col col--2">
            Make LP
          </div>
          <div className="col col--3">
            Your Stake
          </div>
          <div className="col col--4">
            Deposit
          </div>
        </div>
        <div className="burn-farms__header bf-restrict-withdraw">
          <div className="col col--0">
            Pair
          </div>
          <div className="col col--1">
            Your LP
          </div>
          <div className="col col--2">
            Break LP
          </div>
          <div className="col col--3">
            Your Stake
          </div>
          <div className="col col--4">
            Withdraw
          </div>
        </div>
        <div className="burn-farms__header bf-restrict-burn">
          <div className="col col--0">
            Pair
          </div>
          <div className="col col--1">
            Allocation
          </div>
          <div className="col col--2">
            Increase AP
          </div>
          <div className="col col--3">
            Rate per Year
          </div>
          <div className="col col--4">
            Your Rate per year
          </div>
          
          
        </div>
        <div className="burn-farms__header bf-restrict-info">
          <div className="col col--0">
            Pair
          </div>
          <div className="col col--1">
            Total Stake
          </div>
          <div className="col col--2">
            Your Share 
          </div>
          
          <div className="col col--3">
            Farm APR
          </div>
          <div className="col col--3 col--has6">
            Your CANDLE/year
          </div>
          <div className="col col--4">
            Claim
          </div>
        </div>
        <BurnPool 
          lpname="cult-ftm"
          ratePerBlock={bf_emissionRate}
          totalAP={bf_totalAP}
          poolInfo={bf_poolInfo0}
          userInfo={bf_userInfo0}
          poolValue={
            bf_lpValue(
              reserves_cultftm[1], 
              price_cult).toFixed(2)}
          totalSupply={totalSupply_slp_cult}
          candlePrice={price_candle}
          stake={bf_stake(
            0, bf_ref__stake0,
            () => {
              getTokenBalance(slp_cult, setBal_slp_cult)
            }
            )}
          unstake={bf_unstake(
            0, bf_ref__unstake0,
            () => {
              getTokenBalance(slp_cult, setBal_slp_cult)
            }
            )}
          yourharvest={0}
          alloc={200000}
         
          lpBal={Number(fromWei(bal_slp_cult)).toLocaleString("en-US")}
          checkMakeLP={getBAmount(
            bf_ref__make0, 
            set_bf_state__make0_b, 
            reserves_cultftm, 
            "cult-ftm")}
          makeLP={addLiquidity("cult-ftm", bf_ref__make0, bf_state__make0_b, () => {
            getTokenBalance(slp_cult, setBal_slp_cult)},
              setIsPending, setPendingMessage, PENDING_MESSAGE)}
          makeLPref={bf_ref__make0}
          makeBamt={bf_state__make0_b}
          
          checkBreakLP={getBreakAmount(
            bf_ref__break0, 
            totalSupply_slp_cult, 
            set_bf_state__break0_a, 
            set_bf_state__break0_b, reserves_cultftm, "cult-ftm"
            )}
          breakLP={
            breakLiquidity(
              "cult-ftm", 
              bf_ref__break0, 
              bf_state__break0_a, 
              bf_state__break0_b, 
              () => {
                getTokenBalance(slp_cult, setBal_slp_cult)},
              setIsPending, 
              setPendingMessage, 
              PENDING_MESSAGE)}
          breakLPref={bf_ref__break0}
          breakAamt={bf_state__break0_a}
          breakBamt={bf_state__break0_b}
          maxBreakAmount={()=>{
            bf_ref__break0.current.value = fromWei(bal_slp_cult)
          }}
          stakeRef={bf_ref__stake0}
          maxStakeAmount={()=>{
            bf_ref__stake0.current.value = fromWei(bal_slp_cult)
          }}
          unstakeRef={bf_ref__unstake0}
          maxUnstakeAmount={
            ()=>{
              bf_ref__unstake0.current.value = fromWei(
                (bf_userInfo0.hasOwnProperty('amount')) ? bf_userInfo0.amount : 0
                )
            }
          }
          burn4IncRef={bf_ref__burn4Inc0}
          burn4Inc={burn4AP(
            0, bf_ref__burn4Inc0,
            () => {
              getTokenBalance(candle, setBalCandle)
            }
            )}
          pending={bf_pending0}
          harvest={bf_harvest(0)}
        />
        <BurnPool 
          lpname="xcult-ftm"
          ratePerBlock={bf_emissionRate}
          totalAP={bf_totalAP}
          poolInfo={bf_poolInfo1}
          userInfo={bf_userInfo1}
          candlePrice={price_candle}
          poolValue={
            bf_lpValue(
              reserves_xcultftm[1], 
              price_xcult).toFixed(2)}
            totalSupply={totalSupply_slp_xcult}
          stake={bf_stake(1, bf_ref__stake1,
            () => {
              getTokenBalance(slp_xcult, setBal_slp_xcult)
            })}
          unstake={bf_unstake(
            1, bf_ref__unstake1,
            () => {
              getTokenBalance(slp_xcult, setBal_slp_xcult)
            }
            )}
          lpBal={Number(fromWei(bal_slp_xcult)).toLocaleString("en-US")}
          checkMakeLP={
            getBAmount(
              bf_ref__make1, 
              set_bf_state__make1_b, 
              reserves_xcultftm, 
              "xcult-ftm")}
          makeLP={
            addLiquidity(
              "xcult-ftm", 
              bf_ref__make1, 
              bf_state__make1_b, 
              () => {
                getTokenBalance(slp_xcult, setBal_slp_xcult)},
              setIsPending, setPendingMessage, PENDING_MESSAGE)}
          makeLPref={bf_ref__make1}
          makeBamt={bf_state__make1_b}
          checkBreakLP={getBreakAmount(
            bf_ref__break1, 
            totalSupply_slp_xcult, 
            set_bf_state__break1_a, 
            set_bf_state__break1_b, reserves_cultftm, "xcult-ftm"
            )}
          breakLP={
            breakLiquidity(
              "xcult-ftm", 
              bf_ref__break1, 
              bf_state__break1_a, 
              bf_state__break1_b, 
              () => {
                getTokenBalance(slp_xcult, setBal_slp_xcult)},
              setIsPending, 
              setPendingMessage, 
              PENDING_MESSAGE)}
          breakLPref={bf_ref__break1}
          breakAamt={bf_state__break1_a}
          breakBamt={bf_state__break1_b}
          maxBreakAmount={()=>{
            bf_ref__break1.current.value = fromWei(bal_slp_xcult)
          }}
          stakeRef={bf_ref__stake1}
          maxStakeAmount={()=>{
            bf_ref__stake1.current.value = fromWei(bal_slp_xcult)
          }}
          unstakeRef={bf_ref__unstake1}
          maxUnstakeAmount={
            ()=>{
              bf_ref__unstake1.current.value = fromWei(
                (bf_userInfo1.hasOwnProperty('amount')) ? bf_userInfo1.amount : 0
                )
            }
          }
          burn4IncRef={bf_ref__burn4Inc1}
          burn4DecRef={bf_ref__burn4Dec1}
          pending={bf_pending1}
          harvest={bf_harvest(1)}
        />
        <BurnPool 
          lpname="candle-usdc"
          ratePerBlock={bf_emissionRate}
          totalAP={bf_totalAP}
          poolInfo={bf_poolInfo2}
          userInfo={bf_userInfo2}
          candlePrice={price_candle}
          poolValue={
            bf_lpValue(
              reserves_candleusdc[1], 
              price_candle).toFixed(2)}
            totalSupply={totalSupply_lp_candleusdc}
          stake={bf_stake(2, bf_ref__stake2,
            () => {
              getTokenBalance(lp_candleusdc, setBal_lp_candleusdc)
            })}
          unstake={bf_unstake(
            2, bf_ref__unstake2,
            () => {
              getTokenBalance(lp_candleusdc, setBal_lp_candleusdc)
            }
            )}
          lpBal={Number(fromWei(bal_lp_candleusdc)).toLocaleString("en-US")}
          checkMakeLP={
            getBAmount(
              bf_ref__make2, 
              set_bf_state__make2_b, 
              reserves_candleusdc, 
              "candle-usdc")}
          makeLP={
            addLiquidity(
              "candle-usdc", 
              bf_ref__make2, 
              bf_state__make2_b, 
              () => {
                getTokenBalance(lp_candleusdc, setBal_lp_candleusdc)},
              setIsPending, setPendingMessage, PENDING_MESSAGE)}
          makeLPref={bf_ref__make2}
          makeBamt={bf_state__make2_b}
          checkBreakLP={getBreakAmount(
            bf_ref__break2, 
            totalSupply_lp_candleusdc, 
            set_bf_state__break2_a, 
            set_bf_state__break2_b, reserves_candleusdc, "candle-usdc"
            )}
          breakLP={
            breakLiquidity(
              "candle-usdc", 
              bf_ref__break2, 
              bf_state__break2_a, 
              bf_state__break2_b, 
              () => {
                getTokenBalance(lp_candleusdc, setBal_lp_candleusdc)},
              setIsPending, 
              setPendingMessage, 
              PENDING_MESSAGE)}
          breakLPref={bf_ref__break2}
          breakAamt={bf_state__break2_a}
          breakBamt={bf_state__break2_b}
          maxBreakAmount={()=>{
            bf_ref__break2.current.value = fromWei(bal_lp_candleusdc)
          }}
          stakeRef={bf_ref__stake2}
          maxStakeAmount={()=>{
            bf_ref__stake2.current.value = fromWei(bal_lp_candleusdc)
          }}
          unstakeRef={bf_ref__unstake2}
          maxUnstakeAmount={
            ()=>{
              bf_ref__unstake2.current.value = fromWei(
                (bf_userInfo2.hasOwnProperty('amount')) ? bf_userInfo2.amount : 0
                )
            }
          }
          burn4IncRef={bf_ref__burn4Inc2}
          burn4DecRef={bf_ref__burn4Dec2}
          pending={bf_pending2}
          harvest={bf_harvest(2)}
        />
        <BurnPool 
          lpname="cult-xcult"
          ratePerBlock={bf_emissionRate}
          totalAP={bf_totalAP}
          poolInfo={bf_poolInfo3}
          userInfo={bf_userInfo3}
          candlePrice={price_candle}
           poolValue={
            bf_lpValue(
              reserves_cultxcult[0], 
              price_cult).toFixed(2)}
            totalSupply={totalSupply_lp_cultxcult}
          stake={bf_stake(3, bf_ref__stake3,
            () => {
              getTokenBalance(lp_cultxcult, setBal_lp_cultxcult)
            })}
          unstake={bf_unstake(
            3, bf_ref__unstake3,
            () => {
              getTokenBalance(lp_cultxcult, setBal_lp_cultxcult)
            }
            )}

          lpBal={Number(fromWei(bal_lp_cultxcult)).toLocaleString("en-US")}
          checkMakeLP={
            getBAmount(
              bf_ref__make3, 
              set_bf_state__make3_b, 
              reserves_cultxcult, 
              "cult-xcult")}
          makeLP={
            addLiquidity(
              "cult-xcult", 
              bf_ref__make3, 
              bf_state__make3_b, 
              () => {
                getTokenBalance(lp_cultxcult, setBal_lp_cultxcult)},
              setIsPending, setPendingMessage, PENDING_MESSAGE
                )}
          makeLPref={bf_ref__make3}
          makeBamt={bf_state__make3_b}
          checkBreakLP={getBreakAmount(
            bf_ref__break3, 
            totalSupply_lp_cultxcult, 
            set_bf_state__break3_a, 
            set_bf_state__break3_b, reserves_cultxcult, "cult-xcult"
            )}
          breakLP={
            breakLiquidity(
              "cult-xcult", 
              bf_ref__break3, 
              bf_state__break3_a, 
              bf_state__break3_b, 
              () => {
                getTokenBalance(lp_cultxcult, setBal_lp_cultxcult)},
              setIsPending, 
              setPendingMessage, 
              PENDING_MESSAGE)}
          breakLPref={bf_ref__break3}
          breakAamt={bf_state__break3_a}
          breakBamt={bf_state__break3_b}
          maxBreakAmount={()=>{
            bf_ref__break3.current.value = fromWei(bal_lp_cultxcult)
          }}
          stakeRef={bf_ref__stake3}
          maxStakeAmount={()=>{
            bf_ref__stake3.current.value = fromWei(bal_lp_cultxcult)
          }}
          unstakeRef={bf_ref__unstake3}
          maxUnstakeAmount={
            ()=>{
              bf_ref__unstake3.current.value = fromWei(
                (bf_userInfo3.hasOwnProperty('amount')) ? bf_userInfo3.amount : 0
                )
            }
          }
          burn4IncRef={bf_ref__burn4Inc3}
          burn4DecRef={bf_ref__burn4Dec3}
          pending={bf_pending3}
          harvest={bf_harvest(3)}
        />
        

      </div>

      <div className={"modal modal-minting modal--" + isModalOpen}>
        <h2>You need to mint an item to proceed.</h2>
        <p>{modalText}</p>
        <div>
          <img src={itemImgs[modalID]} />
        </div>
        <p>Alternatively, you can go to the Item Shop and mint a rare version of this item.</p>
        <p>If your $CANDLE allowance to the Cult Items contract is insufficient, an approval transaction will precede the mint transaction.</p>
      </div>
    </div>

  );
}

