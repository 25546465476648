import { tokens, lps, farms, routers } from '../configs/CultTokensConfig.js'
import Web3 from 'web3';
var web3 = new Web3(Web3.givenProvider || 'http://localhost:8545');
const fromWei = (wei) => {
  return Number(web3.utils.fromWei(wei.toString(),"ether"))
}
const allowSpooky = (_token, _tokenName, cb = ()=>{}) => {
  if (_tokenName == "FTM" || _tokenName == "WFTM") {
    cb("10000000000000000000000000000000000")
  } else {
    _token.methods.allowance(window.ethereum.selectedAddress, routers["Spooky"]["address"])
      .call({from: window.ethereum.selectedAddress})
      .then((res) => {
        cb(res)
      }).catch((err)=>{console.log(err)})
  }
  
}

const approveSpooky = (_amt, _token, _tokenName, cb = ()=>{}, setIsPending, setPendingMessage, PENDING_MESSAGE) => {
  setIsPending(true)
  setPendingMessage(
    "Approving Spooky Router to Spend " + 
    Number(fromWei(_amt)).toFixed(2) + " " + _tokenName)
  _token.methods.approve(routers["Spooky"]["address"], _amt)
    .send({from: window.ethereum.selectedAddress})
    .then((res) => {
      setIsPending(false)
      setPendingMessage(PENDING_MESSAGE)
      cb()
    })
}

const isLongOrEnough = (str, val) => {
  let isAllowed = false
  if (str.length > 27) {
    isAllowed = true
  } else {
    if (Number(fromWei(str)) >= Number(val)) {
      isAllowed = true
    }
  }
  return isAllowed
}
const breakLiq = (a_addy, 
                b_addy,
                liquidity,
                amtAMin,
                amtBMin,
                rtr, cb = ()=>{}, _p, _lpName, setIsPending, setPendingMessage, PENDING_MESSAGE) => {
  rtr.methods.removeLiquidity(
                a_addy,
                b_addy,
                liquidity,
                amtAMin,
                amtBMin,
                window.ethereum.selectedAddress,
                (Math.floor(Date.now() / 1000) + 120).toString()
                ).estimateGas(
                  {from: window.ethereum.selectedAddress},
                  function (error, estimatedGas) {
                    if (error != null) { 
                      console.log(error)
                    } else {
                      setIsPending(true)
                      let nma = tokens[_p[0]]["name"]
                      let nmb = tokens[_p[1]]["name"]
                      let dex = lps[_lpName]["dex"]
                      let amtA
                      if (nma == "USDC") {
                        amtA = (Number(amtAMin)/1e6).toFixed(2)
                      } else {
                        amtA = fromWei(amtAMin).toFixed(2)
                      }
                      setPendingMessage("Breaking " + fromWei(liquidity) + " " + _lpName)
                      rtr.methods.removeLiquidity(
                      a_addy,
                      b_addy,
                      liquidity,
                      amtAMin,
                      amtBMin,
                      window.ethereum.selectedAddress,
                      (Math.floor(Date.now() / 1000) + 120).toString()
                      ).send({from: window.ethereum.selectedAddress})
                      .then((res)=>{
                        setIsPending(false)
                        setPendingMessage(PENDING_MESSAGE)
                        console.log(res)
                        
                        cb()
                      }).catch((err)=>{
                        console.log(err)
                        setIsPending(false)
                        setPendingMessage(PENDING_MESSAGE)
                      })
                    }
                  }
                  )
}

const breakLiqFTM = (
                b_addy,
                liquidity,
                amtAMin,
                amtBMin,
                rtr, cb = ()=>{}, _p, _lpName, setIsPending, setPendingMessage, PENDING_MESSAGE) => {
  rtr.methods.removeLiquidityETH(
                b_addy,
                liquidity,
                amtBMin,
                amtAMin,
                window.ethereum.selectedAddress,
                (Math.floor(Date.now() / 1000) + 120).toString()
                ).estimateGas(
                  {from: window.ethereum.selectedAddress},
                  function (error, estimatedGas) {
                    if (error != null) { 
                      console.log(error)
                    } else {
                      setIsPending(true)
                      let nma = "FTM"
                      let nmb = tokens[_p[1]]["name"]
                      let dex = lps[_lpName]["dex"]
                      setPendingMessage("Breaking " + fromWei(liquidity) + " " + _lpName)
                      rtr.methods.removeLiquidityETH(
                        b_addy,
                        liquidity,
                        amtBMin,
                        amtAMin,
                        window.ethereum.selectedAddress,
                        (Math.floor(Date.now() / 1000) + 120).toString())
                        .send({from: window.ethereum.selectedAddress})
                        .then((res)=>{
                          setIsPending(false)
                          setPendingMessage(PENDING_MESSAGE)
                          console.log(res)
                          
                          cb()
                        }).catch((err)=>{
                          console.log(err)
                          setIsPending(false)
                          setPendingMessage(PENDING_MESSAGE)
                        })
                    }
                  }
                  )
}
export const breakLiquidity = (_lpName, ref, a_state, b_state, cb = () => {}, setIsPending, setPendingMessage, PENDING_MESSAGE) => {
  return () => {

  let rtr = new web3.eth.Contract(
    routers["Spooky"]["abi"],
    routers["Spooky"]["address"]
    )
  let _p = tokens[_lpName]["tokens"]
  let a_addy = tokens[_p[0]]["address"]
  let b_addy = tokens[_p[1]]["address"]
  let pair = new web3.eth.Contract(
    tokens[_lpName]["abi"], 
    tokens[_lpName]["address"])
  
  let amtAMin
  let liquidity = web3.utils.toWei(ref.current.value, 'ether')


  if (_lpName=="candle-usdc") {
   
    amtAMin = Math.round(a_state * 0.98 * 1e6).toString()
  } else {

    amtAMin = web3.utils.toWei(
    (a_state * 0.98).toString(), 
    'ether')
  }
 


  
  let amtBMin = web3.utils.toWei(
    (b_state * 0.98).toString(), 
    'ether')
  console.log("checking allowance of a")
  allowSpooky(
    pair, 
    _lpName,
    (resa) => {
      console.log("got allowance of lp", resa)
      let isAllowed = isLongOrEnough(resa,ref.current.value)
      if (isAllowed) {
       
           
      
              console.log("breaking liquidity")
              console.log(
                "breaking", 
                a_addy,
                b_addy,
                liquidity,
                amtAMin,
                amtBMin) 
              if (tokens[_p[0]]["name"] == "FTM" || tokens[_p[0]]["name"] == "WFTM") {
                breakLiqFTM(
                  b_addy,
                  liquidity,
                  amtAMin,
                  amtBMin,
                  rtr, 
                  cb, _p, _lpName, setIsPending, setPendingMessage, PENDING_MESSAGE)
              } else {
                breakLiq(
                  a_addy,
                  b_addy,
                  liquidity,
                  amtAMin,
                  amtBMin,
                  rtr, 
                  cb, _p, _lpName, setIsPending, setPendingMessage, PENDING_MESSAGE)
              }
              
  
      } else { // if allowance a insufficient
        approveSpooky(
          liquidity,
          pair, 
          _lpName, 
          () => {
            if (tokens[_p[0]]["name"] == "FTM" || tokens[_p[0]]["name"] == "WFTM") {
                breakLiqFTM(
                  b_addy,
                  liquidity,
                  amtAMin,
                  amtBMin,
                  rtr, 
                  cb, _p, _lpName, setIsPending, setPendingMessage, PENDING_MESSAGE)
              } else {
                breakLiq(
                  a_addy,
                  b_addy,
                  liquidity,
                  amtAMin,
                  amtBMin,
                  rtr, 
                  cb, _p, _lpName, setIsPending, setPendingMessage, PENDING_MESSAGE)
              }
          }, setIsPending, setPendingMessage, PENDING_MESSAGE
          )
      } 
    })
  }
}