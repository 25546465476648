import { tokens, lps, farms, routers } from '../configs/CultTokensConfig.js'
import Web3 from 'web3';
var web3 = new Web3(Web3.givenProvider || 'http://localhost:8545');
const fromWei = (wei) => {
  return Number(web3.utils.fromWei(wei.toString(),"ether"))
}
const allowSpooky = (_token, _tokenName, cb = ()=>{}) => {
  if (_tokenName == "FTM" || _tokenName == "WFTM") {
    cb("10000000000000000000000000000000000")
  } else {
    _token.methods.allowance(window.ethereum.selectedAddress, routers["Spooky"]["address"])
      .call({from: window.ethereum.selectedAddress})
      .then((res) => {
        cb(res)
      }).catch((err)=>{console.log(err)})
  }
  
}

const approveSpooky = (_amt, _token, _tokenName, cb = ()=>{}, setIsPending, setPendingMessage, PENDING_MESSAGE) => {
  setIsPending(true)
  setPendingMessage(
    "Approving Spooky Router to Spend " + 
    Number(fromWei(_amt)).toFixed(2) + " " + _tokenName)
  _token.methods.approve(routers["Spooky"]["address"], _amt)
    .send({from: window.ethereum.selectedAddress})
    .then((res) => {
      setIsPending(false)
      setPendingMessage(PENDING_MESSAGE)
      cb()
    })
}

const isLongOrEnough = (str, val) => {
  let isAllowed = false
  if (str.length > 27) {
    isAllowed = true
  } else {
    if (Number(fromWei(str)) >= Number(val)) {
      isAllowed = true
    }
  }
  return isAllowed
}
const addLiq = (a_addy,
                b_addy,
                amtADesired,
                amtBDesired,
                amtAMin,
                amtBMin,
                rtr, cb = ()=>{}, _p, _lpName, setIsPending, setPendingMessage, PENDING_MESSAGE) => {
  rtr.methods.addLiquidity(
                a_addy,
                b_addy,
                amtADesired,
                amtBDesired,
                amtAMin,
                amtBMin,
                window.ethereum.selectedAddress,
                (Math.floor(Date.now() / 1000) + 120).toString()
                ).estimateGas(
                  {from: window.ethereum.selectedAddress},
                  function (error, estimatedGas) {
                    if (error != null) { 
                      console.log(error)
                    } else {
                      setIsPending(true)
                      let nma = tokens[_p[0]]["name"]
                      let nmb = tokens[_p[1]]["name"]
                      let dex = lps[_lpName]["dex"]
                      let amtA
                      if (nma == "USDC") {
                        amtA = (Number(amtADesired)/1e6).toFixed(2)
                      } else {
                        amtA = fromWei(amtADesired).toFixed(2)
                      }
                      setPendingMessage("Adding " + amtA + " " + nma + " and " + fromWei(amtBDesired).toFixed(2) + " " + nmb + " to " + dex)
                      rtr.methods.addLiquidity(
                      a_addy,
                      b_addy,
                      amtADesired,
                      amtBDesired,
                      amtAMin,
                      amtBMin,
                      window.ethereum.selectedAddress,
                      (Math.floor(Date.now() / 1000) + 120).toString()
                      ).send({from: window.ethereum.selectedAddress})
                      .then((res)=>{
                        setIsPending(false)
                        setPendingMessage(PENDING_MESSAGE)
                        console.log(res)
                        
                        cb()
                      }).catch((err)=>{
                        console.log(err)
                        setIsPending(false)
                        setPendingMessage(PENDING_MESSAGE)
                      })
                    }
                  }
                  )
}

const addLiqFTM = (
                b_addy,
                amtADesired,
                amtBDesired,
                amtAMin,
                amtBMin,
                rtr, cb = ()=>{}, _p, _lpName, setIsPending, setPendingMessage, PENDING_MESSAGE) => {
  rtr.methods.addLiquidityETH(
                b_addy,
                amtBDesired,
                amtBMin,
                amtAMin,
                window.ethereum.selectedAddress,
                (Math.floor(Date.now() / 1000) + 120).toString()
                ).estimateGas(
                  {from: window.ethereum.selectedAddress, value: amtADesired},
                  function (error, estimatedGas) {
                    if (error != null) { 
                      console.log(error)
                    } else {
                      setIsPending(true)
                      let nma = "FTM"
                      let nmb = tokens[_p[1]]["name"]
                      let dex = lps[_lpName]["dex"]
                      setPendingMessage("Adding " + fromWei(amtADesired).toFixed(2) + " " + nma + " and " + fromWei(amtBDesired).toFixed(2) + " " + nmb + " to " + dex)
                      rtr.methods.addLiquidityETH(
                        b_addy,
                        amtBDesired,
                        amtBMin,
                        amtAMin,
                        window.ethereum.selectedAddress,
                        (Math.floor(Date.now() / 1000) + 120).toString())
                        .send({from: window.ethereum.selectedAddress, value: amtADesired})
                        .then((res)=>{
                          setIsPending(false)
                          setPendingMessage(PENDING_MESSAGE)
                          console.log(res)
                          
                          cb()
                        }).catch((err)=>{
                          console.log(err)
                          setIsPending(false)
                          setPendingMessage(PENDING_MESSAGE)
                        })
                    }
                  }
                  )
}
export const addLiquidity = (_lpName, ref, b_state, cb = () => {}, setIsPending, setPendingMessage, PENDING_MESSAGE) => {
  return () => {

  let rtr = new web3.eth.Contract(
    routers["Spooky"]["abi"],
    routers["Spooky"]["address"]
    )
  let _p = tokens[_lpName]["tokens"]
  let a_addy = tokens[_p[0]]["address"]
  let b_addy = tokens[_p[1]]["address"]
  let a = new web3.eth.Contract(
    tokens[_p[0]]["abi"], 
    a_addy)
  let b = new web3.eth.Contract(
    tokens[_p[1]]["abi"], 
    b_addy)
  let amtADesired, amtAMin
  if (_lpName=="candle-usdc") {
    amtADesired = Math.round(Number(ref.current.value)*1e6).toString()
    amtAMin = Math.round(Number(ref.current.value) * 0.98 * 1e6).toString()
  } else {
    amtADesired = web3.utils.toWei(ref.current.value, 'ether')
    amtAMin = web3.utils.toWei(
    (Number(ref.current.value) * 0.98).toString(), 
    'ether')
  }
 
  let amtBDesired = web3.utils.toWei((Number(b_state) * (1)).toString(), 'ether')

  
  let amtBMin = web3.utils.toWei(
    (b_state * 0.98).toString(), 
    'ether')
  console.log("checking allowance of a")
  allowSpooky(
    a, 
    tokens[_p[0]]["name"],
    (resa) => {
      console.log("got allowance of a", resa)
      let isAllowed = isLongOrEnough(resa,ref.current.value)
      if (isAllowed) {
        allowSpooky(
          b, 
          tokens[_p[1]]["name"],
          (resb)=>{
            console.log("got allowance of b", resb)
            let isAllowedb = isLongOrEnough(resb,b_state)
            if (isAllowedb) {
              console.log("adding liquidity")
              console.log(
                "adding", 
                a_addy,
                b_addy,
                amtADesired,
                amtBDesired,
                amtAMin,
                amtBMin) 
              if (tokens[_p[0]]["name"] == "FTM" || tokens[_p[0]]["name"] == "WFTM") {
                addLiqFTM(
                  b_addy,
                  amtADesired,
                  amtBDesired,
                  amtAMin,
                  amtBMin,
                  rtr, 
                  cb, _p, _lpName, setIsPending, setPendingMessage, PENDING_MESSAGE)
              } else {
                addLiq(
                  a_addy,
                  b_addy,
                  amtADesired,
                  amtBDesired,
                  amtAMin,
                  amtBMin,
                  rtr, 
                  cb, _p, _lpName, setIsPending, setPendingMessage, PENDING_MESSAGE)
              }
              
            } else {
                approveSpooky(
                  amtBDesired,
                  b,
                  tokens[_p[1]]["name"],
                  () => {
                    if (tokens[_p[0]]["name"] == "FTM" || tokens[_p[0]]["name"] == "WFTM") {
                      addLiqFTM(
                        b_addy,
                        amtADesired,
                        amtBDesired,
                        amtAMin,
                        amtBMin,
                        rtr, 
                        cb, _p, _lpName, setIsPending, setPendingMessage, PENDING_MESSAGE)
                    } else {
                      addLiq(
                        a_addy,
                        b_addy,
                        amtADesired,
                        amtBDesired,
                        amtAMin,
                        amtBMin,
                        rtr, 
                        cb, _p, _lpName, setIsPending, setPendingMessage, PENDING_MESSAGE)
                    }
                  }, setIsPending, setPendingMessage, PENDING_MESSAGE
                  )
              }
          }) // end allowSpooky b
      } else { // if allowance a insufficient
        approveSpooky(
          amtADesired,
          a, 
          tokens[_p[0]]["name"], 
          () => {
            allowSpooky(
            b, 
            tokens[_p[1]]["name"],
            (resb)=>{
              console.log("got allowance of b", resb)
              let isAllowedb = isLongOrEnough(resb,b_state)
              if (isAllowedb) {
                console.log("adding liquidity")
                console.log(
                  "adding", 
                  a_addy,
                  b_addy,
                  amtADesired,
                  amtBDesired,
                  amtAMin,
                  amtBMin) 
                if (tokens[_p[0]]["name"] == "FTM" || tokens[_p[0]]["name"] == "WFTM") {
                  addLiqFTM(
                    b_addy,
                    amtADesired,
                    amtBDesired,
                    amtAMin,
                    amtBMin,
                    rtr, 
                    cb, _p, _lpName, setIsPending, setPendingMessage, PENDING_MESSAGE)
                } else {
                  addLiq(
                    a_addy,
                    b_addy,
                    amtADesired,
                    amtBDesired,
                    amtAMin,
                    amtBMin,
                    rtr, 
                    cb, _p, _lpName, setIsPending, setPendingMessage, PENDING_MESSAGE)
                }
                
              } else {
                approveSpooky(
                  amtBDesired,
                  b,
                  tokens[_p[1]]["name"],
                  () => {
                    if (tokens[_p[0]]["name"] == "FTM" || tokens[_p[0]]["name"] == "WFTM") {
                      addLiqFTM(
                        b_addy,
                        amtADesired,
                        amtBDesired,
                        amtAMin,
                        amtBMin,
                        rtr, 
                        cb, _p, _lpName, setIsPending, setPendingMessage, PENDING_MESSAGE)
                    } else {
                      addLiq(
                        a_addy,
                        b_addy,
                        amtADesired,
                        amtBDesired,
                        amtAMin,
                        amtBMin,
                        rtr, 
                        cb, _p, _lpName, setIsPending, setPendingMessage, PENDING_MESSAGE)
                    }
                  }, setIsPending, setPendingMessage, PENDING_MESSAGE
                  )
              }
            }) // end allowSpooky b
          }, setIsPending, setPendingMessage, PENDING_MESSAGE
          )
      } 
    })
  }
}