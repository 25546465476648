import { degenesis, dialog } from '../configs/CandleDegenesisConfig.js'
import { tokens, lps, farms } from '../configs/CultTokensConfig.js'
import { BigNumber } from '@ethersproject/bignumber';
import { fromAddressTo, fromTokenNameToAddress, tokenNameAddyMap, fromTokenNameToDecimals } from './tokenUtilities.js'

const GEN = require("../abis/GenericABI.json")
const rewardToken = tokens["candle"]
// Pu aka Pool Utilities
const fs = (bal, dec = 18) => {
  let x = bal.toString()
  let digits, decimals
  let L = "0", R = "0"
  let output
  //console.log(x.length, dec)
  if (x.length > dec) {
    digits = x.length - dec
    L = x.slice(0,digits)
    R = x.slice(digits)
  } else {
    decimals = dec - x.length
    R = "0".repeat(decimals).concat(x)
  }
  output = L.concat(".").concat(R)
  output = new Intl.NumberFormat().format(output)
  return output
}
export class BF {
	constructor(web3, chef) {
		this.web3 = web3
		this.dec18 = BigNumber.from(10).pow(18)
		this.CHEF = farms[chef]["address"]
		this.CHEFABI = farms[chef]["abi"]
	}


	cleanInputAmount (amt, addy) {
		console.log("triggered cleaning" + tokenNameAddyMap.get(addy).name)

		let cleanInputAmt = amt.toString();
		cleanInputAmt = cleanInputAmt.replaceAll(",","")
		cleanInputAmt = Number(cleanInputAmt)

		let inputDecimals = cleanInputAmt % 1;
		let inputNumber = Math.floor(cleanInputAmt - inputDecimals);
		inputDecimals = inputDecimals.toFixed(18);
		let inputDecimalsReady = inputDecimals.toString().slice(2);
		console.log("decimals", inputDecimalsReady)
		let cleanInput = inputNumber.toString();
		console.log(inputDecimalsReady)
		console.log(cleanInput)
		cleanInput = cleanInput.concat(inputDecimalsReady);
		return cleanInput;
	}

	approveAmount (amt, addy, callback)  {
		console.log("triggered approval")
		const tract = new this.web3.eth.Contract(GEN, addy);
		
		tract.methods.approve(this.CHEF, amt)
			.send({from: window.ethereum.selectedAddress})
				.then(callback)

	}

	checkAllowance (addy, callback)  {
		const tract = new this.web3.eth.Contract(GEN, addy);

		console.log("Checking Allowance")

		tract.methods.allowance(window.ethereum.selectedAddress, this.CHEF)
			.call({from: window.ethereum.selectedAddress})
				.then(callback)

	}

	burnUguToIncreaseAlloc(pid, burnAmount, callback) {
		const tract = new this.web3.eth.Contract(this.CHEFABI, this.CHEF);

		console.log("burning ugu to increase Alloc points")

		tract.methods.burnUGUtoIncreaseAllocPoint(pid, burnAmount)
			.send({from: window.ethereum.selectedAddress})
				.then(callback)
	}


	getBalance (addy, callback) {
		const tract = new this.web3.eth.Contract(GEN, addy);

		console.log("Getting Balance")

		tract.methods.balanceOf(window.ethereum.selectedAddress)
			.call({from: window.ethereum.selectedAddress})
				.then(callback)

	}

	depositAmount (poolId, amt, depositName, callback) {
		console.log("triggered deposit " + poolId)
		const tract = new this.web3.eth.Contract(this.CHEFABI, this.CHEF);
		
		console.log("attempting deposit",poolId, amt)
		tract.methods.deposit(poolId, amt)
			.send({from: window.ethereum.selectedAddress})
				.then(callback)

	}

	harvest (poolId, callback)  {
		console.log("triggered harvest")
		const tract = new this.web3.eth.Contract(this.CHEFABI, this.CHEF);
		tract.methods.deposit(poolId, 0)
			.send({from: window.ethereum.selectedAddress})
				.then(callback)

	}

	withdrawAmount (poolId, amt, depositName, callback) {
		console.log("triggered withdraw")
		const tract = new this.web3.eth.Contract(this.CHEFABI, this.CHEF);
		
		tract.methods.withdraw(poolId, amt)
			.send({from: window.ethereum.selectedAddress})
				.then(callback)
	}

	triggerApproval(addy, callback) {
	
		this.approveAmount(
			addy, 
			() => {
				this.checkAllowance(
					addy, 
					callback
				)
				
			}
		)
				
	}

	getPoolBalance (poolId, callback) {
		const tract = new this.web3.eth.Contract(this.CHEFABI, this.CHEF);

		return tract.methods.userInfo(poolId, window.ethereum.selectedAddress)
			.call.request({from: window.ethereum.selectedAddress}, callback)
				
	}

	getPoolInfo(poolId, callback) {
		const tract = new this.web3.eth.Contract(this.CHEFABI, this.CHEF);

		tract.methods.poolInfo(poolId, window.ethereum.selectedAddress)
			.call({from: window.ethereum.selectedAddress})
				.then(callback)
	}

	getRate(callback) {
		const tract = new this.web3.eth.Contract(this.CHEFABI, this.CHEF);
		//tract.methods.rewardtokenPerBlock()
		tract.methods.acrePerBlock()
			.call({from: window.ethereum.selectedAddress})
					.then(callback)
	}
	getTotalAlloc(callback) {
		const tract = new this.web3.eth.Contract(this.CHEFABI, this.CHEF);
		tract.methods.totalAllocPoint()
			.call({from: window.ethereum.selectedAddress})
					.then(callback)
	}

	getPendingRewards (poolId, callback, isRequest) {
		const tract = new this.web3.eth.Contract(this.CHEFABI, this.CHEF);
		if (!isRequest) {
			//tract.methods.pendingRewards(poolId, window.ethereum.selectedAddress)
			tract.methods.pendingAcre(poolId, window.ethereum.selectedAddress)
				.call({from: window.ethereum.selectedAddress})
					.then(callback)	
		} else {
			//return tract.methods.pendingRewards(poolId, window.ethereum.selectedAddress)
			return tract.methods.pendingAcre(poolId, window.ethereum.selectedAddress)
						.call.request({from: window.ethereum.selectedAddress}, callback)	
		}
		
	}

	


	totalSupply (callback) {
		const tract = new this.web3.eth.Contract(GEN, rewardToken["address"])

		tract.methods.totalSupply().call({from: window.ethereum.selectedAddress})
			.then(callback)
	}
	rewardBalance (callback) {
		const tract = new this.web3.eth.Contract(GEN, rewardToken["address"])

		tract.methods.balanceOf(window.ethereum.selectedAddress).call({from: window.ethereum.selectedAddress})
			.then(callback)
	}

	burnCANDLEtoIncreaseAllocPoint (pid, burnAmount, callback) {
		const tract = new this.web3.eth.Contract(this.CHEFABI, this.CHEF);

		tract.methods.burnCANDLEtoIncreaseAllocPoint(pid, burnAmount)
			.send({from: window.ethereum.selectedAddress})
				.then(callback)
	}

	increaseRate (burnAmount, callback) {
		const tract = new this.web3.eth.Contract(this.CHEFABI, this.CHEF);

		tract.methods.increaseEmissionRate(burnAmount)
			.send({from: window.ethereum.selectedAddress})
				.then(callback)
	}

	decreaseRate (burnAmount, callback) {
		const tract = new this.web3.eth.Contract(this.CHEFABI, this.CHEF);

		tract.methods.decreaseEmissionRate(burnAmount)
			.send({from: window.ethereum.selectedAddress})
				.then(callback)
	}

}

