import React from 'react'
import Web3 from 'web3';
var web3 = new Web3(Web3.givenProvider || 'http://localhost:8545');
const fromWei = (wei) => {
  return Number(web3.utils.fromWei(wei.toString(),"ether"))
}
export const InfoRow = (props) => (
	<div className={"info-row info-row--" + ((props.hasOwnProperty('rowtype')) ? props.rowtype : "")}>
	    <div className="info-row__el info-row__name">{props.rowname}</div>
	    <div className="info-row__el info-row__amt">{Number(props.amt.toFixed(0)).toLocaleString('en-US')}</div>
	    <div className="info-row__el info-row__price">${Number(props.price.toFixed(0)).toLocaleString('en-US')}</div>
	</div>
)

export const InfoRowP = (props) => (
	<div className={"info-row info-row--" + ((props.hasOwnProperty('rowtype')) ? props.rowtype : "")}>
	  	{
	  		//console.log("InfoRowP", props.amt, props.pct)
	  	}
	    <div className="info-row__el info-row__name">{props.rowname}</div>
	    <div className="info-row__el info-row__amt">{Number(props.amt.toFixed(0)).toLocaleString('en-US')}</div>
	    <div className="info-row__el info-row__price">${Number(props.price.toFixed(0)).toLocaleString('en-US')}</div>
		<div className="info-row__el info-row__pct">{(Math.round(props.pct*10000))/100}%</div>
	</div>
)

export const InfoRow_1155 = (props) => (
	<InfoRow
		rowname={props.rowname}
        rowtype="sub"
        amt={
          Number(props.bal)
        }
        price={props.price * Number(props.bal) * props.cost} 
/>
	)

export const HeaderRowP = (props) => (
	<div className={"info-row info-row--header"}>
	  	
	    <div className="info-row__el info-row__name">Token</div>
	    <div className="info-row__el info-row__amt">Amount</div>
	    <div className="info-row__el info-row__price">$-Value</div>
		<div className="info-row__el info-row__pct">%-Share</div>
	</div>
)

export const ClaimRow = (props) => (
	<div className="claim-row">
	    <button onClick={props.claimById}>
	      Claim
	    </button>
	    <input ref={props.claimByID_ref} defaultValue={props.defaultValue} />
	  </div>
	)

export const BoxRow = (props) => (
	<div className={"box-row box-row--" + props.index}>
      {props.children}
    </div>
	)

export const BoxPart = (props) => (
	<div className={"box-part box-part--" + props.parttype}>
		{props.children}
	</div>
)
export const ValEl = (props) => (
    <div className={"val-el val-el--" + props.rowname}>

      
     
      <InfoRowP
        rowname={props.rowname}
        amt={Number(fromWei(props.balState)) + ((props.userInfoState.hasOwnProperty('0')) ? Number(fromWei(props.userInfoState['0'])) : 0)}
        price={props.getNetWorth(
            props.userInfoState, 
            props.balState, 
            props.priceState)}
        pct={(Number(fromWei(props.balState)) + ((props.userInfoState.hasOwnProperty('0')) ? Number(fromWei(props.userInfoState['0'])) : 0))/props.denominator} />
      <InfoRow
        rowtype="sub"
        rowname={props.rowname + " in Wallet"}
        amt={Number(fromWei(props.balState))}
        price={props.priceState * Number(fromWei(props.balState))} 
        pct={Number(fromWei(props.balState))/props.denominator}/>
      {
        //console.log("user info for val el",props.userInfoState)
      }
      <InfoRow
        rowtype="sub"
        rowname={props.rowname + " Staked"}
        amt={((props.userInfoState.hasOwnProperty('0')) ? Number(fromWei(props.userInfoState['0'])) : 0)}
        price={props.getNetWorth(
            props.userInfoState,
            0,
            props.priceState)}
        pct={((props.userInfoState.hasOwnProperty('0')) ? Number(fromWei(props.userInfoState['0'])) : 0)/props.denominator} />
     
    </div>
    

    )