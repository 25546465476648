import Web3 from 'web3';
import { tokens, lps, farms, routers } from '../configs/CultTokensConfig.js'
import { nfts } from '../configs/CultNFTConfig.js'
var web3 = new Web3(Web3.givenProvider || 'http://localhost:8545');

export const skullys = new web3.eth.Contract(
  nfts["skullys"]["abi"], 
  nfts["skullys"]["address"])

export const popskullys = new web3.eth.Contract(
  nfts["popskullys"]["abi"], 
  nfts["popskullys"]["address"])

export const ghostlys = new web3.eth.Contract(
  nfts["ghostlys"]["abi"], 
  nfts["ghostlys"]["address"])

export const cult = new web3.eth.Contract(
  tokens["cult"]["abi"], 
  tokens["cult"]["address"])

export const xcult = new web3.eth.Contract(
  tokens["xcult"]["abi"], 
  tokens["xcult"]["address"])

export const candle = new web3.eth.Contract(
  tokens["candle"]["abi"], 
  tokens["candle"]["address"])

export const soul = new web3.eth.Contract(
  tokens["soul"]["abi"],
  tokens["soul"]["address"]
  )

export const farm = new web3.eth.Contract(
  farms["gate"]["abi"],
  farms["gate"]["address"]
  )

export const bf = new web3.eth.Contract(
  farms["burn"]["abi"],
  farms["burn"]["address"]
  )

export const cultItems = new web3.eth.Contract(
  nfts["items"]["abi"],
  nfts["items"]["address"]
  )

export const lp_cult = new web3.eth.Contract(
  lps["cult-soul"]["abi"], 
  lps["cult-soul"]["address"])

export const lp_xcult = new web3.eth.Contract(
  lps["xcult-soul"]["abi"], 
  lps["xcult-soul"]["address"])
export const lp_candle = new web3.eth.Contract(
  lps["candle-soul"]["abi"], 
  lps["candle-soul"]["address"])
export const lp_candleusdc = new web3.eth.Contract(
  lps["candle-usdc"]["abi"], 
  lps["candle-usdc"]["address"])
export const slp_cult = new web3.eth.Contract(
  lps["cult-ftm"]["abi"], 
  lps["cult-ftm"]["address"])

export const slp_xcult = new web3.eth.Contract(
  lps["xcult-ftm"]["abi"], 
  lps["xcult-ftm"]["address"])

export const lp_cultxcult = new web3.eth.Contract(
  lps["cult-xcult"]["abi"], 
  lps["cult-xcult"]["address"])